<template>
<div>
  
                        

      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    


            <h4 class="col m-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Bons De Charge ( Brouillons )    </h4> 

                
                    <div class="col">



                          
                                    <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                                                <i class="fa fa-home fa-2x"></i>
                                    </b-button>



                                    <b-button pill size="sm"  class="m-2 float-right" variant="info" @click="fetchList">
                                        <i class="fa fa-sync-alt"></i>
                                        <span> Actualiser</span> 
                                    </b-button>
                    </div>
            </div>
       



        <b-table 

        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"

        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
                    <b-button pill size="sm" class="mr-2 float-right" variant="outline-primary" @click="edit_click(row.item)">
                            <i class="fa fa-pencil-alt"></i>
                    
                    </b-button>

                </template>
        
        </b-table>

                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>

      </div>




    <!-- les trucs public -->

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";

export default {
 computed: {
           rows_pagin() {
        return this.itemslist.length
      },
    user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    },

  },
    mounted(){
        this.fetchList()
    },
	data () {
		return {
            perPage: 20,
            currentPage: 1,
            itemslist: [],

            fields: [
				{key: 'nom', label: 'Nom', sortable: true},
                {key: 'date', label: 'Date', sortable: true},
                {key: 'zone', label: 'Zone', sortable: true},
                {key: 'vendeur', label: 'Vendeur', sortable: true},
                {key: 'camion', label: 'Camion', sortable: true},
                {key: 'depot', label: 'Dépôt', sortable: true},
                 {key: 'user', label: 'Utilisateur', sortable: true},
                { key: 'actions', label: '' }
            ],
            //public trucs

            isLoading: false
		}
	},
    validations: {
            itemForm: {
                nom: {
                    required
                }
            }
    },
    methods :{
        NewBon()
        {
                    this.$router.push({ 
                        name: 'menuCharg'
                    });
        },
        edit_click(item) {

                this.$confirm("Voulez-vous vraiment modifier ce Bon ??")
                 .then(() => {


                        this.$router.push({ 
                        name: 'bonCharge', 
                        params: { 
                        ResivedId: item.id
	} 
});

                })
                .catch(error=> {
                console.log(error)

                });


      },

        del_click(item) {

                                this.$confirm("Voulez-vous vraiment supprimer ce Bon??")
                                .then(() => {

                                    this.BnDell(item.id)
                                })
                                .catch(error=> {
                                console.log(error)

                                    });




      },
        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/bnChargeListType/0/0/'+this.user_dep)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        BnDell(id_bn){

            this.isLoading=true;

            this.$http.delete('/bnCharges/'+id_bn)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.itemslist.findIndex(item => item.id === id_bn)
                    this.itemslist.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },


    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>