import { render, staticRenderFns } from "./StockBoites.vue?vue&type=template&id=7905ea92&scoped=true&"
import script from "./StockBoites.vue?vue&type=script&lang=js&"
export * from "./StockBoites.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7905ea92",
  null
  
)

export default component.exports