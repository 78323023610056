<template>
<div class="p-4 back_princ">



 <div class="card border-left-primary shadow p-2 m-1">   
 <div class="row m-0">
            <div class="col">
            <h4 class=" font-weight-lighter text-info m-1 float-left"> <span class="fa fa-qrcode"></span> Codes</h4> 

                <b-button pill size="sm"  class="mt-1 ml-2 float-left" variant="outline-primary" @click="print_pdf">
                            <i class="fa fa-print"></i>
                    <span> Imprimer</span> 
                </b-button>


            </div>

            <div class="col">
                <div class="input-group">
                                    <select class="form-control border border-ligth " id="selected_zone" v-model="selected_zone">
                                            <option v-for="item in zones" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>
                                     </select>


                                    <div class="input-group-prepend">
                                        <button @click="code_gen" class="btn  btn-light  border border-ligth rounded-right " type="submit" id="button-addon1">   
                                        <span class="fa fa-search"></span> Liste
                                        </button>
                                    </div>

                </div>
            </div>
              <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Rechercher" class="col ml-4 mr-4"></b-form-input>

</div>
</div>

<div class="row p-1 mt-4">

            <!-- Earnings (Monthly) Card Example -->
            <div v-for="item in filteritems" :key="item.id" class="col-xl-4 col-md-6 mb-4">
              <div class="card border-left-primary shadow h-100 py-2">
            <div class="col">
                            <b-button  size="sm"  class="float-right p-0 text-danger" variant="link" @click="dell_code(item)">
                                        <i class="fa fa-user-times"></i>
                            </b-button>

                      <div class="h6 float-left font-weight-normal text-danger  pt-1  mb-1">
                        {{item.nom_prenom}} 
                      </div>
            </div>



                      <div class="d-flex justify-content-center">
                      <vue-qrcode v-bind:id="item.id" :value=item.id :options="{
                                              errorCorrectionLevel: 'H',
                                              width: 200,
                                              margin:1
                                            }"
                        ></vue-qrcode>
                      </div>
              </div>
            </div>

</div>


    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import jsPDF from 'jspdf'

export default {
  computed:{
    filteritems() {
            return this.itemslist.filter(category => {
            return category.nom_prenom.toLowerCase().includes(this.filter.toLowerCase())   
      })
    },
  },
      mounted(){

        this.fetchFormZones()
    },
	data () {
		return {
       filter: '',
            selected_zone:'',
            itemslist: [],

            zones: [],

            isSubmitted: false,
            isLoading: false
		}
	},
 methods:{
dell_code(itemm)
{
                    let index = this.itemslist.findIndex(item => item.id === itemm.id)
                    this.itemslist.splice(index, 1)
},
               fetchFormZones: function () {
                    //chager les categories:
                    this.isLoading=true;
                    this.$http.get('/zones')
                    .then((result) => {
                                this.zones = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
           },

print_pdf()
{

                        let zon = ""

                        if(this.selected_zone >0)
                        {
                        let mm=this.zones.findIndex(item => item.id === this.selected_zone)
                        zon= this.zones[mm].nom
                        }



      const doc = new jsPDF(
        {
          orientation: "landscape",
          unit: "mm",
          format: [70, 50]
        }
      );



         this.filteritems.forEach(function(item){

        const contentHtml =document.getElementById(item.id.toString())

        doc.setFontSize(17);
        doc.text("HALIB", 50, 23);
        doc.text("RICH", 51, 29);

        doc.addImage(contentHtml, 2, 3, 41, 41);
        doc.setFontSize(10);
        doc.text(item.nom_prenom, 4, 47);

        doc.addPage();
         });




          doc.save('QR_Code_'+zon+'.pdf')

},

code_gen()
{

                        if(this.selected_zone)
                        {
                    this.isLoading=true;
                    this.$http.get('/listClients/'+this.selected_zone)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
                        }
                        else
                        {
                                        this.flashMessage.show({
                                        status: 'info',
                                        title: 'Sélectionez une zone et Reésayez !!',
                                        })
                        }


},

 },
     components: {
              Loading,   
              VueQrcode
    }

}
</script>
<style scoped>
.back_princ{
  background: #eff7fa;
}

.text_org{
  color: green;
}
</style>