import { render, staticRenderFns } from "./ListBonChargeVal.vue?vue&type=template&id=5a0e7074&scoped=true&"
import script from "./ListBonChargeVal.vue?vue&type=script&lang=js&"
export * from "./ListBonChargeVal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a0e7074",
  null
  
)

export default component.exports