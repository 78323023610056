<template>
<div>
  
                        
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">   

        <h4 class="col ml-3 font-weight-lighter text-info"> <span class="fa fa-tools"></span> Maintenance Des Camions </h4>   
        <div class="mt-1 mb-1 ml-2 d-inline text-secondary font-weight-light col">
            Garage : <span class="d-inline text-info"> {{nGarage}} </span> <br/> 
            Camion : <span class="d-inline text-info"> {{nCamion}} </span>
        </div>

<div class="col">
                <b-button pill size="sm"  class="m-3 float-right" variant="success" @click="add_click($event.target)">
                            <i class="fa fa-plus-square"></i>
                    <span> Ajouter</span> 
                </b-button>

                <b-button pill size="sm"  class="m-3 float-right" variant="info" @click="fetchList">
                    <i class="fa fa-sync-alt"></i>
                    <span> Actualiser</span> 
                </b-button>
</div>
            </div>
       



        <b-table 
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
                    <b-button pill size="sm" class="mr-2 float-right" variant="outline-primary" @click="edit_click(row.item, row.index, $event.target)">
                            <i class="fa fa-pencil-alt"></i>
                    
                    </b-button>
                </template>
        
        </b-table>
      </div>


    <!-- le modal -->
    <b-modal id="banqModal" :title="itemForm.matricul"  hide-footer>
           <form @submit.prevent="handleSubmit">

            <div class="form-group">
                <label for="date">Date</label>
                    <b-form-datepicker
                    id="datepicker-dateformat2"
                    placeholder="Choose a date"
                    v-model="itemForm.date"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.date.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.date.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>



            <div class="form-group">
                <label for="quota">Prix :</label>
                <input type="text" v-model="itemForm.prix" id="quota" name="quota" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.prix.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.prix.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.prix.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>




            <div class="form-group">
            <label for="Description">Description :</label>
            <textarea name="description" id="description"  rows="4" class="form-control" v-model="itemForm.description"
                      :class="{ 'is-invalid': isSubmitted && $v.itemForm.description.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.description.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

            </div>

            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
            </div>


        </form>
    </b-modal>


    <!-- les trucs public -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";

export default {
     props: ['idCamion','idGarage','nCamion','nGarage'],
      computed: {
    user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
      },
    mounted(){
        this.fetchList()
    },
	data () {
		return {
            itemslist: [],
            itemForm: {
                    id:'',
                    date: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    prix:0,
                    description:'',
                    camion_id:'',
                    garage_id:'',
                    etat:0},
            fields: [
				{key: 'date', label: 'Date', sortable: true},
                {key: 'prix', label: 'Prix', sortable: true},
                {key: 'description', label: 'Description', sortable: true},
                { key: 'actions', label: '' }
            ],
            //public trucs
            ModalEdit: false,
            ModalType:'Ajouter',
            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemForm: {
                date: {
                    required
                },
                prix: {
                    required,
                    decimal
                },
                camion_id: {
                    required
                },
                description: {
                    required
                },
                garage_id: {
                    required
                }
            }
    },
    methods :{


        itemslisttore(){


                        this.isLoading=true;

            this.$http.post('/maintCamion',{
            date:this.itemForm.date,
            prix:this.itemForm.prix,
            camion_id:this.itemForm.camion_id,
            description:this.itemForm.description,
            garage_id:this.itemForm.garage_id,
            etat:0
                })
                .then(response => {

                    this.isLoading=false;
                    this.itemslist.unshift(response.data.data);
                    this.$bvModal.hide('banqModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })

                    //valider init:
                    this.isSubmitted = false;

                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        banqueEdit(){

            this.isLoading=true;

            this.$http.patch('/maintCamion/'+this.itemForm.id,{
            date:this.itemForm.date,
            prix:this.itemForm.prix,
            camion_id:this.itemForm.camion_id,
            description:this.itemForm.description,
            garage_id:this.itemForm.garage_id,
            etat:0

                })
                .then(response => {

                    this.isLoading=false;
                    console.log(response.data)
                    this.$bvModal.hide('banqModal')

                    let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                    this.itemslist.splice(index, 1,response.data.data)
                    
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })

                    //valider init:
                    this.isSubmitted = false;


                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

        },
        banqueDell(){ 
                //USER HANDLE
                this.itemForm.user= this.user_name



            this.isLoading=true;
            this.$http.delete('/maintCamion/'+this.itemForm.id)
                .then(response => {
                console.log(response.data)
                    this.isLoading=false;



                    
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                    this.itemslist.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        add_click(button) {
        this.itemForm.id=''
        this.itemForm.date= new Date().toJSON().slice(0,10).replace(/-/g,'-')
        this.itemForm.prix=0
        this.itemForm.description=''

        this.itemForm.camion_id = this.idCamion;

        this.itemForm.garage_id = this.idGarage;

        this.ModalEdit=false;
        this.ModalType='Ajouter'

        this.$root.$emit('bv::show::modal', 'banqModal', button)
      },
        edit_click(item, index, button) {

                this.$confirm("Voulez-vous vraiment modifier cet élément ??")
                 .then(() => {
                this.itemForm.id=item.id
                this.itemForm.date=item.date
                this.itemForm.prix=item.prix

                this.itemForm.description=item.description

                    this.itemForm.camion_id = this.idCamion;

                    this.itemForm.garage_id = this.idGarage;

                this.ModalEdit=true;
                this.ModalType='Modifier'

                this.$root.$emit('bv::show::modal', 'banqModal', button)
                })
                .catch(error=> {
                console.log(error)

                });


      },
        del_click(item) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {
        this.itemForm.id=item.id
        this.itemForm.date= new Date().toJSON().slice(0,10).replace(/-/g,'-')

                this.banqueDell()


                                })
                                .catch(error=> {
                                console.log(error)

                                    });

      },


        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/maintCamionGarage/'+this.idGarage+'/'+this.idCamion)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        handleSubmit() {


                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                            if(this.ModalEdit)
                            {
                                this.banqueEdit()
                            }
                            else
                            {

                                this.itemslisttore()
                            }   

                }

            }

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>