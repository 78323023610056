<template>
<div>
  
            <div class="rounded-lg shadow border border-white bg-white mb-4">
                        <div class="row justify-content-centre align-items-center">    

                                <div class="col-8 p-2 ml-3">
                                    

                                    <h5 class="font-weight-lighter text-secondary ml-2"> <span class="fa fa-file-alt"></span> Trajet Du Vendeur    </h5> 

                                    
                                    <div class="mt-1 ml-2 d-inline text-secondary">Zone : <span class="d-inline text-info"> {{nZon}} </span>  </div>

                                    <div class="mt-1 ml-2 d-inline text-secondary">    - Vendeur : <span class="d-inline text-info"> {{nVend}} </span>  </div>
                                    <div class="mt-1 ml-2 d-inline text-secondary">    - Bon De Charge : <span class="d-inline text-info"> {{nCharg}} </span>  </div>

                                </div>


 
                    <div class="col">

                                        <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                            <i class="fa fa-home fa-2x"></i>
                </b-button>

                    </div>             
                        </div>          
            </div>  



 
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    

            <h5 class="col ml-2 font-weight-lighter text-info">  Nombre Des clients : {{total_dh}}</h5> 

            
                    <div class="col">

                                    <b-button @click="exportDox" pill size="sm"  class="m-3 float-right" variant="info">
                                        <i class="fa fa-print"></i>
                                        <span> Rapport</span> 
                                    </b-button>

                    </div>

            </div>
       



        <b-table 
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >


        </b-table>
      </div>





    <!-- les trucs public -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {
 props: ['nZon','idCharg','nCharg','nVend'],
      computed: {
      total_dh: function() {
      return this.itemslist.length;
    },


    },
    mounted(){

            if(this.idCharg)
            {
            this.fetchList(this.idCharg)
            }
            else
            {
            this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur de Chargement',
                    message: 'Impossible charger les données de ce bon'
            })
            }

    },
	data () {
		return {

            itemslist: [],
            fields: [
                {key: 'id', label: 'N°', sortable: true},
				{key: 'code', label: 'Code', sortable: true},
				{key: 'nom_prenom', label: 'Nom Et Prénom', sortable: true},
                {key: 'zone', label: 'Zone', sortable: true},
                {key: 'datetime', label: 'Date ET Heure', sortable: true},
            ],
            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemFormBon: {
                date: {
                    required
                }

            }

    },
    methods :{
        
        NewBon()
        {
                    this.$router.push({ 
                        name: 'menuCharg'
                    });
        },

exportDox()
        {




        let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Nom & Prénom",
                                            spacing: {
                                before: 100,
                                after: 100
                            },
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Zone",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Date ET Heure",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })]

        this.itemslist.forEach(function(item){

        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.nom_prenom,
                                        spacing: {
                                        before: 50,
                                        after: 50
                                    },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.zone,
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.datetime,
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                    ],
                })

        datrw.push(
        r
        )
        });

                const doc = new Document();

        const table = new Table({

            rows:  datrw
        })



                doc.addSection({


                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                        },
                    },
                    headers: {
                        default: new Header({
                            children: [
                                new Paragraph({
                                    children: [
                            new TextRun({
                                text: "تعاونية حليب الريش",
                                color: '636572',
                                bold: true,

                                })
                                    ],
                                border: {
                                bottom: {
                                        color: "636572",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),
                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },



                    children: [

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Trajet Du Vendeur : "+this.nVend,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }), 
                                new TextRun({
                                text: "     Zone : "+this.nZon,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),
                                new TextRun({
                                text: "     Bon : "+this.nCharg,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            })

                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),




                        table
                        
                        ]
                })







            Packer.toBlob(doc).then(blob => {

        saveAs(blob, "Trajet_Vendeur.docx");

            });

        },

        fetchList: function (id) {
                    this.isLoading=true;
                    
                    this.$http.get('/trajetListeVend/'+id)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        }

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>