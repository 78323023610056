<template>
  

<div  class="card  shadow border border-white bg-white h-100 pb-3 overflow-auto">
    <div class="row pb-1 border-bottom border-info m-3">




    <div class="col-8">
        
        <h5 class="ml-2 text-secondary">Bon de charge (Ventes) : </h5>
        
        <div class="mt-1 ml-2 d-inline text-secondary">Zone : <span class="d-inline text-info"> {{nZon}} </span>  </div>

        <div class="mt-1 ml-2 d-inline text-secondary">    - Vendeur : <span class="d-inline text-info"> {{nVend}} </span>  </div>
        <div class="mt-1 ml-2 d-inline text-secondary">    - Bon De Charge : <span class="d-inline text-info"> {{nCharg}} </span>  </div>

    </div>
    <div class="col-4">
         
                <b-button pill size="sm"  class="m-2 float-right" variant="info" @click="imprim_Click">
                    <i class="fa fa-print"></i>
                    <span> Bon De Décharge</span> 
                </b-button>

    </div>


        </div>

<div class="row h-100 m-2">
  



            <!-- LA PARTIE DES CARDES -->
        <div class="col mr-auto ">

          <!-- Le pred de page -->
           <div class="row mt-2">
            <div class="col">
              <div class="card border-left-success shadow h-100 py-1">
                <div class="card-body p-3">



                        <div class=" d-flex justify-content-center text-info">
                        <i class="fas fa-truck-moving fa-2x" ></i>
                        </div>

                        <div class=" d-flex justify-content-center text-info mb-0">
                        <h5 class="font-weight-lighter pt-2">Camion</h5>
                        </div>

           <form @submit.prevent="handleSubmit">


            <div class="row">
            <div class="form-group col">
                <label for="kilom_depart">Km Départ :</label>
                <input type="text" v-model="itemForm.kilom_depart" id="kilom_depart" name="kilom_depart" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.kilom_depart.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.kilom_depart.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.kilom_depart.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>


            <div class="form-group col">
                <label for="kilom_arrive">Km Arrivé :</label>
                <input type="text" v-model="itemForm.kilom_arrive" id="kilom_arrive" name="kilom_arrive" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.kilom_arrive.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.kilom_arrive.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.kilom_arrive.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>

            </div>

<div class="row">      
            <div class="form-group col">
                <label for="carbur_l">Gazoil ( L ) :</label>
                <input type="text" v-model="itemForm.carbur_l" id="carbur_l" name="carbur_l" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.carbur_l.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.carbur_l.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.carbur_l.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>



            <div class="form-group col">
                <label for="carbur_dh">Gazoil ( Dhs ) :</label>
                <input type="text" v-model="itemForm.carbur_dh" id="carbur_dh" name="carbur_dh" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.carbur_dh.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.carbur_dh.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.carbur_dh.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>
</div>

            <div class="form-group">
                <button v-if="this.is_valider && itemForm.id" type="submit" class="btn  btn-primary btn-block rounded-pill">Modifier</button>
            </div>


        </form>

                </div>
              </div>
            </div>


            <div class="col">
              <div class="card border-left-success shadow h-100 py-1">
                <div class="card-body p-3">



                        <div class=" d-flex justify-content-center text-info">
                        <i class="fas fa-inbox fa-2x" ></i>
                        </div>

                        <div class=" d-flex justify-content-center text-info mb-0">
                        <h5 class="pt-2 font-weight-lighter">Boites / Plateaux</h5>



                        <b-button class="float-right m-0 text-success" variant="link" @click="add_boites_click">
                         <i class="fa fa-plus-square"></i>

                        </b-button>
                        </div>




                           <div  class="input-group  border-bottom border-info  bg-white p-2  justify-content-centre align-items-center text-secondary">

                                                        <h6 for="boite_id" class=" mt-1 mr-3">{{itemBoite.typeBoite}}</h6>



                                                        <input type="text" v-model="itemBoite.qte_decharge" id="qte_decharge" name="qte_decharge" class="form-control form-control-sm ml-1 border border-white" />

                                                        <b-button class="float-right ml-1 mr-2" variant="link" @click="Add_SuiviBoite">
                                                                            <i class="fa fa-marker fa-1x"></i>

                                                        </b-button>
                            </div>

                            <div class="col">
                                
                                            <b-table 
                                            sort-icon-left
                                            small 
                                            borderless
                                            striped 
                                            sticky-header
                                            :fields="fieldsboites" 
                                            :items="itemsSuivieBoites"
                                            >
                                                <template #cell(actions)="row">
                                                        <b-button pill size="sm"  class="mr-2 float-right" variant="link" @click="Edit_boiteSuiv_click(row.item)">
                                                                    <i class="fa fa-upload"></i>
                                                            
                                                        </b-button>
                                                </template>
                                            
                                            </b-table>



                                         
                            </div>

                </div>
              </div>
            </div>



          </div>


            <!-- LES CARTES DES CARTES -->
          <div class="row mt-3">

            <!-- Tasks Card Example -->
            <div class="col">
              <div class="card border-left-info shadow h-100 py-1">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col">

                        <div class=" d-flex justify-content-center text-info">
                        <i class="fas fa-cubes fa-2x" ></i>
                        </div>

                        <div class=" d-flex justify-content-center text-info mb-0">
                        <h5 class="font-weight-lighter">Stock</h5>
                        </div>




                        <b-button pill  size="sm"  class="btn-block p-2" variant="outline-primary" @click="click_StockArriv">
                                    <i class="fa fa-eraser"></i>
                            <span> Stock D'arrivé</span> 
                        </b-button>

                        <b-button pill v-if="idPrime!=0"  size="sm"  class="btn-block p-2" variant="outline-secondary" @click="click_PrimesArriv">
                            <i class="fa fa-eraser"></i>
                            <span> Primes d'arrivé</span> 
                        </b-button>

                        <b-button pill size="sm"  class="btn-block p-2" variant="outline-danger" @click="retour_arts_Click">
                            <i class="fa fa-eraser"></i>
                            <span> Retours / Déchets</span> 
                        </b-button>


                <b-button v-if="this.is_valider && !this.stock_val"  pill size="sm"  class="mt-4 btn-block p-2" variant="success" @click="clickValiderStock">
                            <i class="fa fa-check"></i>
                    <span>   Valider Le Stock</span> 
                </b-button>

                    </div>

                  </div>
                </div>
              </div>
            </div>

            <!-- Earnings (Annual) Card Example -->
            <div class="col">
              <div class="card border-left-success shadow h-100 py-1">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col">

                        <div class=" d-flex justify-content-center text-info">
                        <i class="fas fa-coins fa-2x" ></i>

                        </div>

                        <div class=" d-flex justify-content-center text-info mb-0">
                        <h5 class="font-weight-lighter">Ventes</h5>
                        </div>


                        <div v-if="this.is_valider" class="d-flex justify-content-center mb-2 mt-2">

                          <b-button pill size="sm"  variant="outline-info" @click="imprimfacture_Click">
                                  <i class="fa fa-print"></i>
                          <span> Facture</span>
                          </b-button>
                        <b-button pill size="sm" class="ml-2"  variant="outline-primary" @click="click_AfficherVentes">
                                  <i class="fa fa-pencil-alt"></i>
                          <span>Afficher Ventes</span>
                          </b-button>

                          <b-button pill size="sm" class="ml-2" variant="outline-secondary" @click="paiemVendor_Click">
                                  <i class="fa fa-plus-square"></i>
                          <span> Crédit Vendeur</span>
                          </b-button>

                          <b-button pill size="sm" class="ml-2"  variant="outline-secondary" @click="paiemClient_Click">
                                  <i class="fa fa-plus-square"></i>
                          <span> Crédit Client</span>
                          </b-button>

                        </div>



               
                        <div class=" d-flex justify-content-center p-1">
                                <h6 class="font-weight-normal text-danger">Total : {{totalVentesDH}} DH </h6>

                                <h6 class="font-weight-normal text-secondary ml-3 mr-3"> Crédits : {{total_paiemVendClient}} DH </h6>

                                 <h6 class="font-weight-normal text-info">Net Caisse : {{total_NetCaisse}} DH</h6>

                        </div>


                            <div class="col">
                                
                                            <b-table 
                                            sort-icon-left
                                            small 
                                            borderless
                                            striped 
                                            sticky-header
                                            :fields="fieldsPaieVnd" 
                                            :items="itemsPaiementVend"
                                            >
                                                <template #cell(actions)="row">
                                                        <b-button pill size="sm" class="mr-2 float-right" variant="link" @click="Dell_paimVC_click(row.item)">
                                                                    <i class="fa fa-trash-alt"></i>
                                                            
                                                        </b-button>
                                                </template>
                                            
                                            </b-table>
                                         
                            </div>

                <b-button v-if="total_prim_argent > 0" pill size="sm"  class="mt-4 btn-block p-2" variant="info" @click="fetchFormComp_validerPrimes">
                            <i class="fa fa-check"></i>
                    <span> {{UI_primes_reg}} Primes En Argent {{total_prim_argent}} DH</span> 
                </b-button>

                <b-button v-if="d_gst_tresor==true" pill size="sm"  class="mt-4 btn-block p-2" variant="success" @click="fetchFormComptes_valider">
                            <i class="fa fa-check"></i>
                    <span>   {{lab_validerBn}}  Le Paiement Du Bon</span> 
                </b-button>

                    </div>
                  </div>
                </div>
              </div>
            </div>





          </div>
        

        
        
        </div>   


</div> 


    <!-- le modal -->
    <b-modal id="stockArrivModal" size="lg" hide-footer>


       <b-table 
        small 
        borderless
        striped 
        sticky-header
        :fields="fieldsStokArrv" 
        :items="ListStockArriv"
        >       
        </b-table>


    </b-modal>

    
    <!-- le modal primes -->
    <b-modal id="PrimesModal" size="lg" hide-footer>


       <b-table 
        small 
        borderless
        striped 
        sticky-header
        :fields="fieldsPrimes" 
        :items="ListPrimesAriv"
        >

        
        </b-table>




    </b-modal>


        <!-- le modal primes -->
    <b-modal id="RetoursModal" size="lg" hide-footer>


       <b-table 
        small 
        borderless
        striped 
        sticky-header
        :fields="fieldsRetours" 
        :items="ListRoutourAriv"
        >

        
        </b-table>

    </b-modal>
    


    <!-- le modal -->
    <b-modal id="banqModal" title="Paiement" size="lg" hide-footer>
        <form @submit.prevent="hundelAddPaiemCltVend">

            <div v-if="itemFormPaiem.is_client==1" class="form-group ">

                <label for="client_vend_id" class="form-control-sm mb-0">Client :</label>

                <select class="form-control form-control-sm" id="client_id" v-model="itemFormPaiem.client_vend_id"
                :class="{ 'is-invalid': isSubmitted && $v.itemFormPaiem.client_vend_id.$error }">
                <option v-for="item in ListClients" :key="item.id" v-bind:value="item.id">   
                {{ item.nom_prenom }} - {{ item.code }}
                </option>
                </select>
                <div v-if="isSubmitted && !$v.itemFormPaiem.client_vend_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>


            </div>


            <div class="row">

                <div class="form-group col">
                <label for="date_exp" class="mr-sm-2">Date :</label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-date_exp"
                    placeholder="date"
                    v-model="itemFormPaiem.date"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>


            <div class="form-group col">
                <label for="date_ech" class="mr-sm-2">Date Echéance:</label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-date_ech"
                    placeholder="Date Echéance"
                    v-model="itemFormPaiem.date_ech"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>

            </div>

            <div class="form-group">
                <label for="montant">Montant (DH) :</label>
                <input type="text" v-model="itemFormPaiem.montant" id="montant" name="montant" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemFormPaiem.montant.$error }" />
                <div v-if="isSubmitted && !$v.itemFormPaiem.montant.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemFormPaiem.montant.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>

            <div class="row">

                        <div class="form-group col">
                                        <label for="mode">Mode de paiement :</label>
                                        <select class="form-control" id="mode" v-model="itemFormPaiem.mode_oper"
                                         :class="{ 'is-invalid': isSubmitted && $v.itemFormPaiem.mode_oper.$error }">
                                        <option v-for="item in modes" :key="item.id" v-bind:value="item.mode">   
                                            {{ item.mode }}
                                        </option>
                                        </select>
                                    <div v-if="isSubmitted && !$v.itemFormPaiem.mode_oper.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                        </div>
                        <div class="form-group col">
                            <label for="infos">Infos de paiement :</label>
                            <input type="text" v-model="itemFormPaiem.infos_oper" id="infos" name="infos" class="form-control" />

                        </div>
            </div>





            <div class="form-group">
            <label for="Description">Description :</label>
            <textarea name="description" id="description"  rows="2" class="form-control" v-model="itemFormPaiem.description"></textarea>

            </div>

            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Ajouter</button>
            </div>

        </form>
    </b-modal>



    <!-- le modal -->
    <b-modal id="validerModal" title="Le paiement" hide-footer>
        <form @submit.prevent="handleSubmitReglerBn">

        <div class="mt-1 ml-2  text-secondary font-weight-light">Total Ventes (DH) : 
          <span class="d-inline text-info"> {{totalVentesDH}}</span>  </div>

          <div class="mt-3 ml-2  text-secondary font-weight-light">Total Crédits (DH) : 
          <span class="d-inline text-info"> {{total_paiemVendClient}} </span>  </div>
        
          <div class="mt-3 ml-2  text-secondary font-weight-light">Total Net (DH) : 
          <span class="d-inline text-info"> {{total_NetCaisse}} </span>  </div>




            <div class="form-group  mt-2  ml-2">
            <div class="custom-control custom-switch">
            <input type="checkbox"
             class="custom-control-input" 
             id="is_lot_manag"
                    v-model="itemVal.is_gazoil"
                    name="is_lot_manag"
                    value=true
                    unchecked-value=false>

            <label class="custom-control-label text-secondary font-weight-light" for="is_lot_manag"> Gazoil :</label>

            <span class="d-inline text-info"> {{itemForm.carbur_dh}} </span>
            </div>
            </div>



            <div class="form-group">
                            <label for="bank_compte_id">Compte :</label>
                            <select class="form-control" id="bank_compte_id" v-model="itemVal.bank_compte_id">
                            <option v-for="item in comptes" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>
                            </select>

            </div>




            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block mt-4">{{lab_validerBn}}</button>
            </div>

        </form>
    </b-modal>

    <!-- le modal -->
    <b-modal id="AddBoitesModal" title="Boites / Plateaux" size="lg"  hide-footer>
           <form @submit.prevent="ValhandleSubmit" class="p-2">

            <div class=" d-flex justify-content-center text-info">
            <i class="fas fa-inbox fa-2x"></i>
            </div>

            <div class=" d-flex justify-content-center text-info mb-2">
            <h5 class="font-weight-lighter">Boites / Plateaux</h5>
            </div>

            <div class="row">
                            <div class="input-group mr-3 ml-3 border-bottom border-info rounded bg-info p-2  justify-content-centre align-items-center text-white">

                                                        <label for="boites_id" class=" mt-1">Types :</label>
                                                        <select  class="form-control ml-1 BgSearch" id="boites_id" v-model="itemCamioForm.boites_id">
                                                        <option v-for="item in itemslistBoites" :key="item.id" v-bind:value="item.id">   
                                                            {{ item.nom }}
                                                        </option>
                                                        </select>

                                                        <label for="qte_boites" class="ml-1 mt-1"> Qté Départ :</label>
                                                        <input type="text" v-model="itemCamioForm.qte_boites" id="qte_boites" name="qte_boites" class="form-control ml-1 BgSearch" />

                                                        <b-button pill size="sm"  class="float-right ml-1" variant="info" @click="Add_NewSuiviBoite">
                                                                            <i class="fa fa-plus-square"></i>
                                                                    <span> Ajouter</span> 
                                                        </b-button>
                            </div>

                            <div class="col">
                                
                                            <b-table 
                                            sort-icon-left
                                            small 
                                            borderless
                                            striped 
                                            sticky-header
                                            :fields="fieldsboitesadd" 
                                            :items="itemsSuivieBoites"
                                            >
                                                <template #cell(actions)="row">
                                                        <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_boites_click(row.item)">
                                                                    <i class="fa fa-trash-alt"></i>
                                                            
                                                        </b-button>
                                                </template>
                                            
                                            </b-table>
                            </div>
            </div>

        </form>
    </b-modal>

    
    <!-- le modal -->
    <b-modal id="validerPrimArgentModal" title="Valider Les Primes En Argent" hide-footer>
        <form @submit.prevent="handleSubmitReglerPrimes">

        <div class="mt-1 ml-2 mb-3  text-secondary font-weight-light">Total Des Primes En Argent (DH) : 
          <span class="d-inline text-info"> {{total_prim_argent}}</span>  </div>


            <div class="form-group">
                            <label for="bank_compte_id">Compte :</label>
                            <select class="form-control" id="bank_compte_id" v-model="itemVal.bank_compte_id">
                            <option v-for="item in comptes" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>
                            </select>

            </div>




            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block mt-4">{{UI_primes_reg}}</button>
            </div>

        </form>
    </b-modal>

      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";
 import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
 import { saveAs } from 'file-saver';

export default {

 props: ['idZon','nZon','idCharg','nCharg','nVend','id_Vend','idPrime','is_val','is_stockval'],

    computed: {
      user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
      total_paiemVendClient: function() {
      return this.itemsPaiementVend.reduce((a, b) => +a + +b.montant, 0);
    },

      total_NetCaisse: function() {
      return this.totalVentesDH-this.total_paiemVendClient;
    },

      total_facture: function() {
       return this.Listfacture.reduce((a, b) => +a + +b.total_dh, 0);
    },

    d_gst_tresor() {
      return this.$store.getters.get_gst_tresor;
    },
    total_prim_argent: function() {  
    let res = this.ListPrimesAriv.filter(it => it.is_argent ==1 && it.etat ==1);     
    return res.reduce((a, b) => +a + +b.qte, 0);

    },
    },
    mounted(){

            if(this.is_val==true)
            {
                    this.is_valider=true
                    this.lab_validerBn="Valider"
            }
            else
            {
                this.is_valider=false

                this.lab_validerBn="Dévalider"
            }


            if(this.is_stockval==1)
            {
            this.stock_val = true;
            }
            else
            {
                this.stock_val = false;
            }

            if(this.idCharg)
            {
            this.fetchKmCamion(this.idCharg)
            this.TotauxAgriculteurs(this.idCharg)

            this.fetchListArtsCharg(this.idCharg)

            this.fetchBoitesEtSuivie_paiem(this.idCharg)

            if(this.idPrime > 0)
            {
                this.fetchListPrimes(this.idPrime)
            }


            
            }
            else
            {
            this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur de Chargement',
                    message: 'Impossible charger les données de ce bon'
            })
            }

    },
    data () {
		return {
            itemCamioForm: {
                    id:'',
                    date: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    boites_id:'',
                    qte_boites:0,
                    user:'user'
                    },

            fieldsboitesadd: [
                {key: 'boite', label: ''},
                {key: 'qte_charge', label: ''},
                {key: 'actions', label: '' }
            ],
    
    dateToday: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
    lab_validerBn:"Valider",
    is_valider:0,
    stock_val:0,
    comptes:[],
    modes:[],
    Listfacture:[],
            ListClients:[],
            itemsPaiementVend:[],
            fieldsPaieVnd: [
                {key: 'montant', label: ''},
                {key: 'infos_oper', label: ''},
                {key: 'date_ech', label: ''},
                {key: 'actions', label: '' }
            ],
            itemVal: {
                date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                user:'user',
                  total_caisse:'',
                  bank_compte_id:'',
                  is_gazoil:false,
                  regler:0
                      },
            itemFormPaiem: {
                    id:'',
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    montant:'',
                    mode_oper:'',
                    infos_oper:'',
                    date_ech:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    description:'',
                    etat:0,
                    is_client:0,
                    user:'user',
                    bn_charge_id:'',
                    client_vend_id:''},

            itemsSuivieBoites:[],
            fieldsboites: [
                {key: 'boite', label: ''},
                {key: 'qte_charge', label: ''},
                {key: 'qte_decharge', label: ''},
                {key: 'actions', label: '' }
            ],

            itemslistBoites:[],

            itemBoite: {
                                        id:'',
                                        date:'',
                                        qte_charge:'',
                                        qte_decharge:0,
                                        user:'user',
                                        bn_charge_id:'',
                                        boite_id:'',
                                        typeBoite:''

                    },

            itemForm: {
                    id:'',
                    date: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    kilom_depart:'',
                    kilom_arrive:'',
                    carbur_l:'',
                    carbur_dh:0,
                    camion_id:'',
                    charge_id:0},

            totalVentesDH:0,

            fieldsStokArrv: [
                {key: 'ref', label: 'Réf'},
                {key: 'nom_lot', label: 'Lot'},
                {key: 'qte', label: 'Quantité Départ'},
                {key: 'qte_retour', label: 'Quantité Arrivé'},
                {key: 'description', label: 'Description'},
            ],
            fieldsPrimes: [

                {key: 'client', label: 'Client'},
                {key: 'ref', label: 'Réf'},
                {key: 'nom_lot', label: 'Lot'},
                {key: 'qte', label: 'Quantité'},
                {key: 'is_argent', label: 'Argent', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }, sortable: true},
                {key: 'etat', label: 'Récupéré', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }, sortable: true},
            ],
            fieldsRetours: [
                {key: 'ref', label: 'Réf'},
                {key: 'total_qte', label: 'Total Retour'},
                {key: 'total_gratuit', label: 'Total Gratuité'},

            ],
            ArticlesListCharg:[],
            ListStockArriv:[],
            ListRoutourAriv:[],
            ListPrimesAriv:[],

            is_primes_reg: 1,

            UI_primes_reg: "Valider",

            isSubmitted: false,
            isLoading: false,



		}
    },
    validations: {
            itemForm: {
                date: {
                    required
                },
                kilom_depart: {
                    required,
                    decimal
                },
                kilom_arrive: {
                    required,
                    decimal
                },
                carbur_l: {
                    required,
                    decimal
                },
                carbur_dh: {
                    required,
                    decimal
                },
                charge_id: {
                    required
                },
                camion_id: {
                    required
                }
            }

    },
 methods:{

        del_boites_click(itemA){

            this.isLoading=true;

            this.$http.delete('/boiteSuivi/'+itemA.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })
                    let index = this.itemsSuivieBoites.findIndex(item => item.id === itemA.id)

                    this.itemsSuivieBoites.splice(index, 1)
                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        Add_NewSuiviBoite()
        {
                            if(this.itemCamioForm.qte_boites&&this.itemCamioForm.boites_id)
                            {
                                            this.isLoading=true;

                                        this.$http.post('/boiteSuivi',{
                                        date:this.itemCamioForm.date,
                                        qte_charge:this.itemCamioForm.qte_boites,
                                        qte_decharge:0,
                                        user:this.user_name,
                                        bn_charge_id:this.idCharg,
                                        boite_id:this.itemCamioForm.boites_id

                                            })
                                            .then(response => {

                                                this.isLoading=false;
                                                
                                            // console.log(response)

                                            this.itemsSuivieBoites.unshift(response.data.data);

                                                //afficher message
                                                this.flashMessage.show({
                                                status: 'success',
                                                title: 'Données sauvegardées',
                                                message: 'Les données sont sauvegardées correctement '
                                            })


                                            })
                                            .catch(error=> {console.log(error)
                                            this.isLoading=false;


                                                            this.flashMessage.show({
                                            status: 'error',
                                            title: 'Erreur De Sauvegarde',
                                            message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                            })
                                            });
                            }
                            else
                            {
                                            this.flashMessage.show({
                                            status: 'info',
                                            title: 'Vérifiez les données et Reésayez !!',
                                            })
                            }
        },
        add_boites_click()
        {
            this.itemCamioForm.qte_boites = ''
            this.itemCamioForm.boites_id=''
            this.$root.$emit('bv::show::modal', 'AddBoitesModal')
        },
        imprimfacture_Click()
        {
                    this.isLoading=true;
                    this.$http.get('/ventesFacture/'+this.idCharg)
                    .then((result) => {
                                this.Listfacture = result.data.data

                                
                                this.isLoading=false;


                               this.exportDoxfacture()
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        exportDoxfacture()
        {

        let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 5000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Référence",
                                                spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Total Quantité",
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Total DH",
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),





                    ],
                })]

        this.Listfacture.forEach(function(item){

        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 5000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.ref,
                                        spacing: {
                                                    before: 100,
                                                    after: 100,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.total_qte.toString(),

                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.total_dh.toString(),

                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })

        datrw.push(
        r
        )
        });

                const doc = new Document();

        const table = new Table({

            rows:  datrw
        })



                doc.addSection({


                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                        },
                    },
                    headers: {
                        default: new Header({
                            children: [
                                new Paragraph({
                                    children: [
                            new TextRun({
                                text: "تعاونية حليب الريش",
                                color: '636572',
                                bold: true,

                                })
                                    ],
                                border: {
                                bottom: {
                                        color: "636572",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),
                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },



                    children: [

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Facture",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI"
                            }),
                            ],

                            heading: HeadingLevel.HEADING_1,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 200,
                                    before: 200
                                },
                        }),




                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "N° : "+this.idCharg+"          Vendeur : "+this.nVend,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                            heading: HeadingLevel.HEADING_5,
                            alignment: AlignmentType.LEFT,

                                spacing: {
                                    after: 100,
                                    before: 100
                                },
                        }),
                        
                        

                        new Paragraph({



                            children: [
                                new TextRun({
                                text: "De la part de : ",
                                color: '5d83a0',
                                bold: false,
                                font: "Segoe UI"
                            }),
                                new TextRun({
                                text: this.user_name,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),
                            new TextRun({
                                text: "          Date : ",
                                color: '5d83a0',
                                bold: false,
                                font: "Segoe UI"
                            }),

                                new TextRun({
                                text: this.dateToday,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            })
                            ],

                            heading: HeadingLevel.HEADING_5,
                                                alignment: AlignmentType.LEFT,

                                spacing: {
                                    after: 300,
                                    before: 100
                                },
                        }),

                        table,
                        new Paragraph({

                                    bidirectional: true,

                                    children: [

                                    new TextRun({
                                        text: "Total (DH) :",
                                        color: '636572',
                                        bold: true,
                                        font: "Segoe UI"
                                    }),

                                    new TextRun({
                                        text: this.total_facture,
                                        color: '636572',
                                        bold: true,
                                        font: "Segoe UI"
                                    }),

                                    
                                    
                                    ],

                                
                                    heading: HeadingLevel.HEADING_2,
                                    alignment: AlignmentType.CENTER,
                                        spacing: {
                                            after: 400
                                        },
                                }),
                        
                        ]
                })







            Packer.toBlob(doc).then(blob => {

        saveAs(blob, this.nCharg+"_facture.docx");

            });

        },
        imprim_Click()
        {
                    this.isLoading=true;
                    this.$http.get('/retourQteDedharg/'+this.idCharg)
                    .then((result) => {
                                this.ListRoutourAriv = result.data.data

                                
                                this.isLoading=false;


                               this.exportDox()
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        exportDox()
        {



        let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Référence",
                                                spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Lot",
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Qté Départ",
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Qté Arrivé",
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Description",
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                    ],
                })]

        this.ArticlesListCharg.forEach(function(item){

        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.ref,
                                        spacing: {
                                                    before: 100,
                                                    after: 100,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.nom_lot,
                                                spacing: {
                                                    before: 10,
                                                    after: 10,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.qte.toString(),

                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.qte_retour.toString(),

                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.description,
                                        spacing: {
                                                    before: 10,
                                                    after: 10,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })

        datrw.push(
        r
        )
        });

        //tableau retour:
                                let datrwRet =[new TableRow({
                                            children: [
                                                new TableCell({
                                                    width: {
                                                        size: 4000,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "Référence",
                                                                    spacing: {
                                                                    before: 100,
                                                                    after: 100
                                                                },
                                                                heading: HeadingLevel.HEADING_4,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),


                                                new TableCell({
                                                    width: {
                                                        size: 3000,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "Retours",
                                                                heading: HeadingLevel.HEADING_4,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),

                                                new TableCell({
                                                    width: {
                                                        size: 3000,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "Gratuités",
                                                                heading: HeadingLevel.HEADING_4,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),

                                            ],
                                        })]

                                this.ListRoutourAriv.forEach(function(itemr){

                                let rr = new TableRow({
                                            children: [
                                                new TableCell({
                                                    width: {
                                                        size: 4000,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: itemr.ref,
                                                                spacing: {
                                                                before: 50,
                                                                after: 50
                                                            },
                                                                heading: HeadingLevel.HEADING_6,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),


                                                new TableCell({
                                                    width: {
                                                        size: 3000,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: itemr.total_qte.toString(),
                                                                heading: HeadingLevel.HEADING_6,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),

                                                new TableCell({
                                                    width: {
                                                        size: 3000,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: itemr.total_gratuit.toString(),
                                                                heading: HeadingLevel.HEADING_6,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),




                                            ],
                                        })

                                datrwRet.push(
                                rr
                                )
                                });

        //fin tableau retour


        //tableau boites:
                                let datrwBoit =[new TableRow({
                                            children: [
                                                new TableCell({
                                                    width: {
                                                        size: 4500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "Type",
                                                                    spacing: {
                                                                    before: 100,
                                                                    after: 100
                                                                },
                                                                heading: HeadingLevel.HEADING_4,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),


                                                new TableCell({
                                                    width: {
                                                        size: 2500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "Qté Charge",
                                                                heading: HeadingLevel.HEADING_4,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),
                                                new TableCell({
                                                    width: {
                                                        size: 2500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "Qté Décharge",
                                                                heading: HeadingLevel.HEADING_4,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),

                                            ],
                                        })]

                                this.itemsSuivieBoites.forEach(function(itemr){

                                let rrr = new TableRow({
                                            children: [
                                                new TableCell({
                                                    width: {
                                                        size: 4500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: itemr.boite,
                                                                spacing: {
                                                                before: 50,
                                                                after: 50
                                                            },
                                                                heading: HeadingLevel.HEADING_6,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),


                                                new TableCell({
                                                    width: {
                                                        size: 3000,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: itemr.qte_charge.toString(),
                                                                heading: HeadingLevel.HEADING_6,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),

                                                new TableCell({
                                                    width: {
                                                        size: 3000,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: itemr.qte_decharge.toString(),
                                                                heading: HeadingLevel.HEADING_6,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),

                                            ],
                                        })

                                datrwBoit.push(
                                rrr
                                )
                                });

        //fin tableau retour

        //tableau camion

        let datrwmm =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Km Départ",
                                                spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Km Arrivé",
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Gazoil (L)",
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Gazoil (DH)",
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        })



                    ],
                })]

        let rmm = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.itemForm.kilom_depart.toString(),
                                        spacing: {
                                                    before: 100,
                                                    after: 100,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.itemForm.kilom_arrive.toString(),
                                                spacing: {
                                                    before: 10,
                                                    after: 10,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.itemForm.carbur_l.toString(),

                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.itemForm.carbur_dh.toString(),

                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        })


                    ],
                })

        datrwmm.push(
        rmm
        )

        //fin tableau camion


        const doc = new Document();

        const tablestock = new Table({

            rows:  datrw
        })


        const tableretour = new Table({

            rows:  datrwRet
        })

        const tableboite = new Table({

            rows:  datrwBoit
        })

        const tablecamion = new Table({

            rows:  datrwmm
        })

                doc.addSection({


                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                        },
                    },
                    headers: {
                        default: new Header({
                            children: [
                                new Paragraph({
                                    children: [
                            new TextRun({
                                text: "تعاونية حليب الريش",
                                color: '636572',
                                bold: true,

                                })
                                    ],
                                border: {
                                bottom: {
                                        color: "636572",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),
                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },



                    children: [

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Bon De Décharge",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI"
                            }),
                            ],

                            heading: HeadingLevel.HEADING_1,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 200,
                                    before: 200
                                },
                        }),




                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "N° Charge : "+this.nCharg+"     Zone : "+this.nZon+"     Vendeur : "+this.nVend,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                            heading: HeadingLevel.HEADING_5,
                            alignment: AlignmentType.LEFT,

                                spacing: {
                                    after: 100,
                                    before: 100
                                },
                        }),
                        
                        

                        new Paragraph({



                            children: [
                                new TextRun({
                                text: "De la part de : ",
                                color: '5d83a0',
                                bold: false,
                                font: "Segoe UI"
                            }),
                                new TextRun({
                                text: this.user_name,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),
                            new TextRun({
                                text: "          Date : ",
                                color: '5d83a0',
                                bold: false,
                                font: "Segoe UI"
                            }),

                                new TextRun({
                                text: this.dateToday,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            })
                            ],

                            heading: HeadingLevel.HEADING_5,
                                                alignment: AlignmentType.LEFT,

                                spacing: {
                                    after: 300,
                                    before: 100
                                },
                        }),

                        tablestock,
                        
                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Retours / Déchets / Gratuités",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                            heading: HeadingLevel.HEADING_1,
                            alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 100,
                                    before: 100
                                },
                        }),
                        tableretour,
                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Boites / Plateaux",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                            heading: HeadingLevel.HEADING_1,
                            alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 100,
                                    before: 100
                                },
                        }),
                        tableboite,
                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Camion",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                            heading: HeadingLevel.HEADING_1,
                            alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 100,
                                    before: 100
                                },
                        }),
                        tablecamion
                        
                        ]
                })







            Packer.toBlob(doc).then(blob => {

        saveAs(blob, this.nCharg+"_Décharge.docx");

            });

        },
        handleSubmitReglerPrimes()
        {

              if(!this.itemVal.bank_compte_id)
              {
                this.flashMessage.show({
                status: 'info',
                title: 'Sélectionnez un compte !!',
                })   
              }
            else
            {
                    //procedure du modification
                                this.isLoading=true;

                                this.$http.post('/reglerPrimArgent',{
                                date:this.itemVal.date, 
                                user:this.user_name, 
                                regler:this.itemVal.regler,
                                montant:this.total_prim_argent,
                                bank_compte_id:this.itemVal.bank_compte_id,
                                prime_id:this.idPrime

                                    })
                                    .then(response => {

                                        this.isLoading=false;
                                        console.log(response.data)
                                        this.$bvModal.hide('validerPrimArgentModal')

                                        //changer le flag
                                        this.is_primes_reg = this.itemVal.regler

                                    if(this.is_primes_reg==1)
                                    {
                                        this.UI_primes_reg = "Dévalider"
                                    }
                                    else
                                    {

                                        this.UI_primes_reg = "Valider"
                                    }


                                        this.flashMessage.show({
                                        status: 'success',
                                        title: 'Données modifiées',
                                        message: 'Les données sont modifiées correctement'
                                        })


                                        })
                                        .catch(error=> {console.log(error)
                                        this.isLoading=false;


                                        this.flashMessage.show({
                                        status: 'error',
                                        title: 'Erreur De Modification',
                                        message: 'Impossible  de modifier les données .. Réessayez !!'
                                    })
                                    });
                }




        },
        handleSubmitReglerBn()
        {

              if(!this.itemVal.bank_compte_id)
              {
                this.flashMessage.show({
                status: 'info',
                title: 'Sélectionnez un compte !!',
                })   
              }
            else
            {

                    //USER HUNDEL
                    this.itemVal.user= this.user_name
  
                    //procedure du modification
                                this.isLoading=true;

                                this.$http.post('/reglerBnCharg',{

                                date:this.itemVal.date, 
                                user:this.itemVal.user,    
                                total_ttc:this.totalVentesDH,
                                caisse_ttc:this.itemVal.total_caisse,
                                regler:this.itemVal.regler,
                                is_gazoil:this.itemVal.is_gazoil,
                                prix_gazoil:this.itemForm.carbur_dh,
                                bank_compte_id:this.itemVal.bank_compte_id,
                                bn_charge_id:this.idCharg

                                    })
                                    .then(response => {

                                        this.isLoading=false;
                                        console.log(response.data)
                                        this.$bvModal.hide('validerModal')
                    //retoure au menu principal:
                                        this.$router.push({ 
                                            name: 'menuCharg'
                                        });
                                        



                                        this.flashMessage.show({
                                        status: 'success',
                                        title: 'Données modifiées',
                                        message: 'Les données sont modifiées correctement'
                                        })


                                        })
                                        .catch(error=> {console.log(error)
                                        this.isLoading=false;


                                        this.flashMessage.show({
                                        status: 'error',
                                        title: 'Erreur De Modification',
                                        message: 'Impossible  de modifier les données .. Réessayez !!'
                                    })
                                    });
                }




        },
        fetchFormComptes_valider: function () {
            //comptes :
                    this.isLoading=true;
                    this.$http.get('/bankComptes')
                    .then((result) => {
                                this.comptes = result.data.data
                                this.isLoading=false;


                    //caluler_totalcaisse.

                    this.itemVal.total_caisse =this.totalVentesDH - this.itemsPaiementVend.reduce((a, b) => +a + +b.montant, 0);

                                if(this.is_valider==true)
                                {
                                this.itemVal.regler=1
                                }
                                else
                                {
                                this.itemVal.regler=0
                                }    
                                 this.$root.$emit('bv::show::modal', 'validerModal')
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        fetchFormComp_validerPrimes: function () {
            //comptes :
                    this.isLoading=true;
                    this.$http.get('/bankComptes')
                    .then((result) => {
                                this.comptes = result.data.data
                                this.isLoading=false;


                                if(this.is_primes_reg==1)
                                {
                                this.itemVal.regler=0
                                }
                                else
                                {
                                this.itemVal.regler=1
                                }    
                                 this.$root.$emit('bv::show::modal', 'validerPrimArgentModal')
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        Dell_paimVC_click(itemA)
        {
                this.isLoading=true;

                this.$http.delete('/paiemVendClients/'+itemA.id)
                    .then(response => {
                    console.log(response.data)
                    this.isLoading=false;   
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données Supprimées',
                    message: 'Les données sont supprimées correctement'
                    })
                        let index = this.itemsPaiementVend.findIndex(item => item.id === itemA.id)

                        this.itemsPaiementVend.splice(index, 1)
                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Suppression',
                    message: 'Impossible  de supprimer les données .. Réessayez !!'
                    })
                    });
          
        },
        paiemClient_Click()
        {

          //charger le mode de payement et CLIENTS ZONE
            this.fetchModePayem()
            this.fetchClientsZone()

                  this.itemFormPaiem.date=new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                  this.itemFormPaiem.montant=''
                  this.itemFormPaiem.mode_oper=''
                  this.itemFormPaiem.infos_oper=''
                  this.itemFormPaiem.date_ech=new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                  this.itemFormPaiem.description=''
                  this.itemFormPaiem.etat=0
                  this.itemFormPaiem.is_client=1
                  this.itemFormPaiem.bn_charge_id = this.idCharg
                  this.itemFormPaiem.client_vend_id=''


                  this.$root.$emit('bv::show::modal', 'banqModal')


        },
        paiemVendor_Click()
        {
          //charger le mode de payement
            this.fetchModePayem()
                  this.itemFormPaiem.date=new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                  this.itemFormPaiem.montant=0
                  this.itemFormPaiem.mode_oper=''
                  this.itemFormPaiem.infos_oper=this.nVend
                  this.itemFormPaiem.date_ech=new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                  this.itemFormPaiem.description=''
                  this.itemFormPaiem.etat=0
                  this.itemFormPaiem.is_client=0
                  this.itemFormPaiem.bn_charge_id = this.idCharg
                  this.itemFormPaiem.client_vend_id=this.id_Vend


                  this.$root.$emit('bv::show::modal', 'banqModal')








        },
        fetchClientsZone()
        {
                    this.isLoading=true;
                    this.$http.get('/clientMinDeZone/'+this.idZon)
                    .then((result) => {
                                this.ListClients = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });



        },
        fetchModePayem()
        {
                              //chager les modes:
                          this.isLoading=true;
                          this.$http.get('/bankModes')
                          .then((result) => {
                                      this.modes = result.data.data
                                      this.isLoading=false;
                          })
                          .catch(error=> {
                                      console.log(error)
                                      this.isLoading=false;
                                      this.flashMessage.show({
                                      status: 'error',
                                      title: 'Erreur de Chargement',
                                      message: 'Impossible charger les données.. Actualisez et Réessayez'
                                      })
                          });



        },
        hundelAddPaiemCltVend()
        {

            //USER HUNDEL
this.itemFormPaiem.user= this.user_name

//handel nomclient:
if(this.itemFormPaiem.is_client==1)
{

    
        let mm=this.ListClients.findIndex(item => item.id === this.itemFormPaiem.client_vend_id)

        this.itemFormPaiem.infos_oper = this.ListClients[mm].nom_prenom

}

                  this.isLoading=true;

                  this.$http.post('/paiemVendClients',{
                  date:this.itemFormPaiem.date,
                  montant:this.itemFormPaiem.montant,
                  mode_oper:this.itemFormPaiem.mode_oper,
                  infos_oper:this.itemFormPaiem.infos_oper,
                  date_ech:this.itemFormPaiem.date_ech,
                  description:this.itemFormPaiem.description,
                  etat:this.itemFormPaiem.etat,
                  is_client:this.itemFormPaiem.is_client,
                  user:this.itemFormPaiem.user,
                  bn_charge_id:this.itemFormPaiem.bn_charge_id,
                  client_vend_id:this.itemFormPaiem.client_vend_id

                      })
                      .then(response => {

                          this.isLoading=false;
                          this.itemsPaiementVend.unshift(response.data.data);
                          this.$bvModal.hide('banqModal')

                          //afficher message
                          this.flashMessage.show({
                          status: 'success',
                          title: 'Données sauvegardées',
                          message: 'Les données sont sauvegardées correctement '
                      })


                      })
                      .catch(error=> {console.log(error)
                      this.isLoading=false;


                                      this.flashMessage.show({
                      status: 'error',
                      title: 'Erreur De Sauvegarde',
                      message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                      })
                      });

        },
        fetchBoitesEtSuivie_paiem(id_chrg) {
                    this.isLoading=true;
                    this.$http.get('/boites')
                    .then((result) => {
                                this.itemslistBoites = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

//charger le suivie :
                    this.isLoading=true;
                    this.$http.get('/boiteSuiviCharg/'+id_chrg)
                    .then((result) => {
                                this.itemsSuivieBoites = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

//charger Paiments clients vendeurs :


                    this.isLoading=true;
                    this.$http.get('/paiemVendClinChrg/'+id_chrg+'/0')
                    .then((result) => {
                                this.itemsPaiementVend = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        Edit_boiteSuiv_click(itemA){

                                        this.itemBoite.id=itemA.id
                                        this.itemBoite.date=itemA.date
                                        this.itemBoite.qte_charge=itemA.qte_charge
                                        this.itemBoite.qte_decharge=itemA.qte_decharge
                                        this.itemBoite.user=itemA.user
                                        this.itemBoite.bn_charge_id=itemA.bn_charge_id
                                        this.itemBoite.boite_id=itemA.boite_id


                                        this.itemBoite.typeBoite=itemA.boite


        },
        Add_SuiviBoite()
        {
                        //USER HUNDEL
this.itemBoite.user= this.user_name


                            if(this.itemBoite.id && this.itemBoite.qte_decharge)
                            {
                                            this.isLoading=true;

                                        this.$http.patch('/boiteSuivi/'+this.itemBoite.id,{
                                        date:this.itemBoite.date,
                                        qte_charge:this.itemBoite.qte_charge,
                                        qte_decharge:this.itemBoite.qte_decharge,
                                        user:this.itemBoite.user,
                                        bn_charge_id:this.itemBoite.bn_charge_id,
                                        boite_id:this.itemBoite.boite_id

                                            })
                                            .then(response => {

                                                this.isLoading=false;
                                                
                                            // console.log(response)

                                            let index = this.itemsSuivieBoites.findIndex(item => item.id === this.itemBoite.id)
                                            this.itemsSuivieBoites.splice(index, 1,response.data.data)

                                            //initialiser ID
                                            this.itemBoite.id=''

                                                //afficher message
                                                this.flashMessage.show({
                                                status: 'success',
                                                title: 'Données sauvegardées',
                                                message: 'Les données sont sauvegardées correctement '
                                            })


                                            })
                                            .catch(error=> {console.log(error)
                                            this.isLoading=false;


                                                            this.flashMessage.show({
                                            status: 'error',
                                            title: 'Erreur De Sauvegarde',
                                            message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                            })
                                            });
                            }
                            else
                            {
                                            this.flashMessage.show({
                                            status: 'info',
                                            title: 'Vérifiez les données et Reésayez !!',
                                            })
                            }
        },
        fetchKmCamion(id_chrg)
        {
          //verifier kilometrage
                      this.isLoading=true;
                      this.$http.get('/kilomCamionCharg/'+id_chrg)
                      .then((result) => {

                      this.isLoading=false;

                      this.itemForm.id = result.data.data.id

                      this.itemForm.date = result.data.data.date
                      this.itemForm.kilom_depart = result.data.data.kilom_depart
                      this.itemForm.kilom_arrive = result.data.data.kilom_arrive
                      this.itemForm.carbur_l = result.data.data.carbur_l
                      this.itemForm.carbur_dh = result.data.data.carbur_dh
                      this.itemForm.camion_id = result.data.data.camion_id
                      this.itemForm.charge_id = result.data.data.charge_id



                      })
                      .catch(error=> {
                                  console.log(error)
                                  this.isLoading=false;
                                  this.itemForm.id='';

                      });
        },
        retour_arts_Click()
        {
                    this.isLoading=true;
                    this.$http.get('/retourQteDedharg/'+this.idCharg)
                    .then((result) => {
                                this.ListRoutourAriv = result.data.data

                                
                                this.isLoading=false;


                                this.$root.$emit('bv::show::modal', 'RetoursModal')
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        fetchListPrimes(id_prim)
        {
                    this.isLoading=true;
                    this.$http.get('/detailPrimClient/'+id_prim)
                    .then((result) => {
                                this.ListPrimesAriv = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

                    //get primes
                    this.isLoading=true;
                    this.$http.get('/primClients/'+id_prim)
                    .then((result) => {

                                    if(result.data.data.regl==1)
                                    {
                                        this.is_primes_reg = 1
                                        this.UI_primes_reg = "Dévalider"
                                    }
                                    else
                                    {
                                        this.is_primes_reg = 0
                                        this.UI_primes_reg = "Valider"
                                    }

                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },
        fetchListArtsCharg(id_bn)
        {
                    this.isLoading=true;
                    this.$http.get('/bnChargeDetailItems/'+id_bn)
                    .then((result) => {
                                this.ArticlesListCharg = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },
        click_StockArriv()
        {
          this.ListStockArriv= this.ArticlesListCharg

          this.$root.$emit('bv::show::modal', 'stockArrivModal')
        },
        click_PrimesArriv()
        {

          this.$root.$emit('bv::show::modal', 'PrimesModal')
        },
        clickValiderStock()
        {
        
                                this.$confirm("Voulez-vous vraiment VALIDER le stock d'arrivé ??")
                                .then(() => {

                                    this.validerStock(this.idCharg,this.idPrime)
                                })
                                .catch(error=> {
                                console.log(error)

                                    });

        },
        validerStock(id_bn,id_prim)
        {

            this.isLoading=true;

            this.$http.get('/validStockDecharg/'+id_bn+'/'+id_prim+'/1')
                .then(response => {
                console.log(response.data)
                this.isLoading=false; 
                
                
this.stock_val = true;

                this.flashMessage.show({
                status: 'success',
                title: 'Bon valider',
                message: 'Les données sont validées correctement'
                })

                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Validation',
                message: 'Impossible  de valider les données .. Réessayez !!'
                })
                });

        },
        TotauxAgriculteurs(id_chrg) {
                    this.isLoading=true;
                    this.$http.get('/totalVentesDH/'+id_chrg)
                    .then((result) => {


                                this.totalVentesDH = result.data.data[0].total_ventdh


                                    if(!this.totalVentesDH)
                                    {
                                    this.totalVentesDH=0
                                    }
                                    else
                                    {
                                      let number =Number(this.totalVentesDH)
                                      this.totalVentesDH = number.toFixed(2)
                                    }

                                    //fin calcul effacer loader
                                    this.isLoading=false;


                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        click_AfficherVentes()
        {                                this.$confirm("Voulez-vous vraiment Afficher / Modifeir les ventes ??")
                                .then(() => {

                                   
                        this.$router.push({ 
                        name: 'bnChargeVentes', 
                        params: { 
                        idZon: this.idZon,
                        nZon: this.nZon,
                        idCharg: this.idCharg,
                        nCharg: this.nCharg,
                        nVend: this.nVend,
                        id_Vend: this.id_Vend,
                        idPrime:this.idPrime,
                        is_val:this.is_val,
                        is_stockval:this.is_stockval  
                                         
                        } 
                    });


                                })
                                .catch(error=> {
                                console.log(error)

                                    });
        },
        handleSubmit()
        {
                this.isSubmitted = true;
                
                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {





                      if(this.itemForm.id)
                      {
                                      this.isLoading=true;

                                      this.$http.patch('/kilomCamion/'+this.itemForm.id,{
                                      date:this.itemForm.date,
                                      kilom_depart:this.itemForm.kilom_depart,
                                      kilom_arrive:this.itemForm.kilom_arrive,
                                      carbur_l:this.itemForm.carbur_l,
                                      carbur_dh:this.itemForm.carbur_dh,
                                      charge_id:this.itemForm.charge_id,
                                      camion_id:this.itemForm.camion_id,

                                          })
                                          .then(response => {

                                              this.isLoading=false;
                                              
                                              this.itemForm.id = response.data.data.id;

                                              console.log(response.data.data.id)

                                              //afficher message
                                              this.flashMessage.show({
                                              status: 'success',
                                              title: 'Données sauvegardées',
                                              message: 'Les données sont sauvegardées correctement '
                                          })


                                          })
                                          .catch(error=> {console.log(error)
                                          this.isLoading=false;


                                                          this.flashMessage.show({
                                          status: 'error',
                                          title: 'Erreur De Sauvegarde',
                                          message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                          })
                                          });
                      }
                      else
                      {
                                          this.flashMessage.show({
                                          status: 'info',
                                          title: 'Vérifiez les données et Reésayez !!',
                                          })
                      }
                }
        }

},
components: {
              Loading      
}


}
</script>

<style>
.Pgheader {
  background-color: blue;
  opacity: 0.3;
}
.BgSearch {
  background-color:#BEE5EB;
  opacity: 0.9;
}

</style>