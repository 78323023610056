<template>
<div class="p-4 back_princ">

<div class=" d-flex justify-content-center text-info">
 <i class="fas fa-file-alt fa-4x"></i>
</div>

<div class=" d-flex justify-content-center text-info mb-4">
 <h2 class="font-weight-lighter">Primes Clients</h2>
</div>




<div class=" row p-4">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_new">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Nouveaux Bon De Primes</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-plus-square fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>


</div>





<div class=" row p-4">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_brouillon">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="font-weight-bold text-secondary  text-uppercase ">Liste Des Bons <span class="text-warning">( Brouillon ) </span></div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-edit fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Earnings (Annual) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2"  @click="click_valid">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary  text-uppercase">Liste Des Bons <span class="text-success">( Arhivés ) </span></div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-check-square fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

</div>




  </div>
</template>

<script>
export default {

 methods:{
       click_new()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'bnPrimClient'

                    });
      },
      click_brouillon()
      {
                       //goto first page:
                    this.$router.push({ 
                        name: 'listBnPrimClient'

                    });
      },
      click_valid()
      {
                        //goto first page:
                    this.$router.push({ 
                        name: 'listBnPrimClientVal'

                    });
      },


 }
}
</script>
<style scoped>
.back_princ{
  background: #eff7fa;
}

.text_org{
  color: green;
}
</style>