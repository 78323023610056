<template>
  

  

<div  class="card rounded-lg shadow border border-white bg-white h-100 mb-0 pb-3 overflow-auto" >
    <div class="card-header bg-transparent">
             
           

<div class="row">


    <div class="col">
        <h5 class="ml-2 text-secondary font-weight-light"> <span class="fa fa-file-alt"></span> Bon de charge : <span class="ml-2 text-success"> {{nomBon}} </span> </h5>
        
        
        <div class="mt-1 ml-2 d-inline text-secondary font-weight-light">Zone : <span class="d-inline text-info"> {{nom_zon}} </span>  </div>

        <div class="mt-1 ml-2 d-inline text-secondary font-weight-light">    - Vendeur : <span class="d-inline text-info"> {{nVend}} </span>  </div>
        <div class="mt-1 ml-2 d-inline text-secondary font-weight-light">    - Dépôt : <span class="d-inline text-info"> {{nDep}} </span>  </div>

    </div>

    <div class="col">
         

                <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                            <i class="fa fa-home fa-2x"></i>
                </b-button>

                <b-button v-if="d_gst_charg_val==true" pill size="sm"  class="m-2 float-right" variant="success" @click="ValiderBnCharge">
                            <i class="fa fa-check"></i>
                    <span> Valider /Imp.</span> 
                </b-button>


                <b-button pill size="sm"  class="m-2 float-right" variant="danger" @click="del_BonClick">
                    <i class="fa fa-trash-alt"></i>
                    <span> Supp.</span> 
                </b-button>



                <b-button v-if="ArticlesCmdVend.length>0" pill size="sm"  class="m-2 float-right" variant="secondary" @click="cmdVend_click">
                    <i class="fa fa-paste"></i>
                    <span> Commande</span> 
                </b-button>

    </div>

</div>


    </div>




    <div class="row card-body h-100 p-0 m-0">
<div class="p-0">
        <b-button v-b-toggle.collap-categ.collap-articl size="lg" class=" ml-0 mr-0 pl-1 pr-1 float-left text-secondary" variant="link">
        <i class="fa fa-bars"></i>                
        </b-button> 
</div>
  
                <b-collapse id="collap-categ" visible  class="col-2 h-100 mr-3 ml-0 mt-2 p-0">

                            <div class="card border-success h-100 overflow-auto">
                            <div class="card-header  bg-success border-success p-1">
                                            <b-form-input v-model="searchCateg" class="border-info" id="search-cat" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                                           
                                            <b-button  @click="AfficherTousArts" size="sm"  class="ml-1 mb-1 mt-1 p-0 text-white text-decoration-none" variant="link">
                                            <i class="fa fa-list-alt mr-1"></i>  
                                            Tous Les Articles              
                                            </b-button> 
                            </div>

                            <b-list-group flush >
                            <b-list-group-item  v-for="item in filterCategorie" :key="item.id" button class="p-1" variant="success" @click="cat_click(item.id)">
                                    {{ item.nom }} 
                                    <p> <small>{{item.description}}</small> </p>
                            </b-list-group-item>

                            </b-list-group>
                            </div>



                    
                </b-collapse>

                <b-collapse id="collap-articl" visible  class="col-2 h-100 mr-0 mt-2 p-0">


                            <div class="card border-info h-100  overflow-auto">
                            <div class="card-header  bg-info border-info p-1">
                                <b-form-input v-model="serchArt"  class="border-info" id="search-art" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                            
                                        <div class="custom-control custom-switch form-control-sm ml-1">
                                        <input type="checkbox"
                                        class="custom-control-input" 
                                        id="isCodeBarreSearch"
                                                v-model="isCodeBarreSearch"
                                                name="isCodeBarreSearch"
                                                value=true
                                                unchecked-value=false>

                                        <label class="custom-control-label text-white  mb-0" for="isCodeBarreSearch">Code Barre</label>
                                        </div>
                            </div>

                            <b-list-group flush  >
                       
                            <b-list-group-item  v-for="item in filterArticle" :key="item.id" button class="p-1" variant="info" @click="art_click(item.id)">
                                 {{ item.ref }}
                                 <p> <small>{{item.description}}</small> </p>
                                 </b-list-group-item>

                          

                            </b-list-group>
                            </div>






                </b-collapse>

                <div class="col h-100 overflow-auto mt-2 mr-4 ml-1">


            <form @submit.prevent="handleSubmit" >

            <div class="row">
            <div class="form-group col">
                <label for="ref" class="form-control-sm mb-0">Référence</label>
                <input type="text" v-model="itemForm.ref" id="ref" name="ref" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.ref.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.ref.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>

            <div class="form-group col">
                <label for="code_bar" class="form-control-sm mb-0">Code Barre</label>
                <input type="text" v-model="itemForm.code_bar" id="code_bar" name="code_bar" class="form-control form-control-sm"/>
            </div>

            </div>

            <div class="row"> 
            <div class="form-group col">

                <label for="lot_id" class="form-control-sm mb-0">Lot :</label>

                <select class="form-control form-control-sm" id="lot_id" v-model="itemForm.lot_id"
                :class="{ 'is-invalid': isSubmitted && $v.itemForm.lot_id.$error }">
                <option v-for="item in ListLots" :key="item.id" v-bind:value="item.id">   
                {{ item.nom }} - {{ item.qte }}
                </option>
                </select>
                <div v-if="isSubmitted && !$v.itemForm.lot_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>


            </div>




            <div class="form-group col">
                <label for="prix" class="form-control-sm mb-0">Prix TTC : </label>
                <input type="text" v-model="itemForm.prix" id="prix" name="prix" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.prix.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.prix.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.prix.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>
            </div>

            <div class="form-group ">
                <label for="qte_min" class="form-control-sm mb-0">Quantité : </label>
                <input type="text" v-model="itemForm.qte" id="qte_min" name="qte_min" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.qte.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.qte.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.qte.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>


            <div class="form-group">
            <textarea name="description" id="description"  rows="2"  placeholder="Description ..." class="form-control form-control-sm" v-model="itemForm.description"></textarea>
            </div>

            <div class="form-group">
                <button type="submit" class="btn btn-secondary btn-block">Ajouter Article/Produit</button>
            </div>

            </form>


            

        <b-table 
        sort-icon-left
        small 
        responsive 
        :fields="fieldsArticle" 
        :items="ArticlesList"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
            </template>
        
        </b-table>


         

</div>

  </div>





    <!-- le modal -->
    <b-modal id="cmdModal" title="Commande Vendeur" size="lg" hide-footer>


       <b-table 
        small 
        borderless
        striped 
        sticky-header
        :fields="fieldsCmdVend" 
        :items="ArticlesCmdVend"
        >

                    <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-secondary" @click="Addcmd_click(row.item)">
                                <i class="fa fa-search"></i>
                        
                    </b-button>
            </template>
        </b-table>
    </b-modal>

    <!-- le modal -->
    <b-modal id="ValidationModal" title="Validation" size="lg"  hide-footer>
           <form @submit.prevent="ValhandleSubmit" class="p-2">

            <div class=" d-flex justify-content-center text-info">
            <i class="fas fa-inbox fa-2x"></i>
            </div>

            <div class=" d-flex justify-content-center text-info mb-2">
            <h5 class="font-weight-lighter">Boites / Plateaux</h5>
            </div>

            <div class="row">
                            <div class="input-group mr-3 ml-3 border-bottom border-info rounded bg-info p-2  justify-content-centre align-items-center text-white">

                                                        <label for="boites_id" class=" mt-1">Types :</label>
                                                        <select  class="form-control ml-1 BgSearch" id="boites_id" v-model="itemCamioForm.boites_id">
                                                        <option v-for="item in itemslistBoites" :key="item.id" v-bind:value="item.id">   
                                                            {{ item.nom }}
                                                        </option>
                                                        </select>

                                                        <label for="qte_boites" class="ml-1 mt-1"> Qté Départ :</label>
                                                        <input type="text" v-model="itemCamioForm.qte_boites" id="qte_boites" name="qte_boites" class="form-control ml-1 BgSearch" />

                                                        <b-button pill size="sm"  class="float-right ml-1" variant="info" @click="Add_SuiviBoite">
                                                                            <i class="fa fa-plus-square"></i>
                                                                    <span> Ajouter</span> 
                                                        </b-button>
                            </div>

                            <div class="col">
                                
                                            <b-table 
                                            sort-icon-left
                                            small 
                                            borderless
                                            striped 
                                            sticky-header
                                            :fields="fieldsboites" 
                                            :items="itemsSuivieBoites"
                                            >
                                                <template #cell(actions)="row">
                                                        <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_boites_click(row.item)">
                                                                    <i class="fa fa-trash-alt"></i>
                                                            
                                                        </b-button>
                                                </template>
                                            
                                            </b-table>
                            </div>
            </div>


            <div class="row m-0">
                        <div class="col p-2 ">
                               <b-button type="submit" pill  class="btn btn-block " variant="outline-success">
                                        <i class="fa fa-check"></i>
                                <span> Valider</span> 
                            </b-button>

                        </div>
                        <div class="col p-2">
                            <b-button pill   class="btn btn-block" variant="outline-info" @click="exportDox">
                                <i class="fa fa-print"></i>
                                <span>   Imprimer</span> 
                            </b-button>
                        </div>
            </div>
        </form>
    </b-modal>


      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";
 import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
 import { saveAs } from 'file-saver';

export default {
 props: ['idBon','nomBon','nom_zon','idDep','nDep','nVend','idCamion','idPrim','idVend'],

 
    computed: {
            user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
    d_gst_charg_val() {
      return this.$store.getters.get_gst_charg_val;
    },
    filterArticle() {
            return this.listArt.filter(article => {

            if(this.isCodeBarreSearch)
            {
                if(article.code_bar)
                {
                return article.code_bar.toLowerCase().includes(this.serchArt.toLowerCase())
                }

            }
            else
            {
                return article.ref.toLowerCase().includes(this.serchArt.toLowerCase())
            }

      })
    },
    filterCategorie() {
            return this.listCat.filter(category => {
            return category.nom.toLowerCase().includes(this.searchCateg.toLowerCase())   
      })
    }
    },
    mounted(){

            if(this.idBon)
            {
            this.fetchCategorieList()
            this.fetchListArts(this.idBon)

            this.fetchCmdVendList(this.idVend)


            }
            else
            {
            this.flashMessage.show({
                                            status: 'error',
                                            title: 'Erreur de Chargement',
                                            message: 'Impossible de charger les données de ce bon'
            })
            }




    },
    data () {
		return {


            itemCamioForm: {
                    id:'',
                    date: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    boites_id:'',
                    qte_boites:0,
                    user:'user'
                    },

            itemsSuivieBoites:[],
            fieldsboites: [
                {key: 'boite', label: ''},
                {key: 'qte_charge', label: ''},
                {key: 'actions', label: '' }
            ],

            itemslistBoites:[],

            dateToday: new Date().toJSON().slice(0,10).replace(/-/g,'-'),

            fieldsArticle: [
                {key: 'ref', label: 'Réf'},
                {key: 'qte', label: 'Quantité'},
                {key: 'prix', label: 'Prix'},
                {key: 'nom_lot', label: 'Lot'},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],

            fieldsCmdVend: [
                {key: 'ref', label: 'Réf'},
                {key: 'qte', label: 'Quantité'},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],
            ArticlesCmdVend:[],
            ListLots:[],
            isCodeBarreSearch: false,
            searchCateg:'',
            serchArt:'',
            ArticlesList:[],
            listCat: [],
            listArt: [],
            itemForm: {
                    id:'',
                    ref:'',
                    code_bar:'',
                    qte:'',
                    nom_lot:'',
                    lot_id:'',
                    prix:'',
                    article_id:'',
                    description:''},

            ListPrimesAriv:[],

            isSubmitted: false,
            isLoading: false
		}
    },
    validations: {
            itemForm: {
                ref: {
                    required
                },
                qte: {
                    required,
                    decimal
                },
                lot_id: {
                    required
                },
                nom_lot: {
                    required
                },
                prix: {
                    required,
                    decimal
                },
                article_id: {
                    required
                }
            }

    },
    methods:{

        del_boites_click(itemA){

            this.isLoading=true;

            this.$http.delete('/boiteSuivi/'+itemA.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })
                    let index = this.itemsSuivieBoites.findIndex(item => item.id === itemA.id)

                    this.itemsSuivieBoites.splice(index, 1)
                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        Add_SuiviBoite()
        {
                            if(this.itemCamioForm.qte_boites&&this.itemCamioForm.boites_id)
                            {
                                            this.isLoading=true;

                                        this.$http.post('/boiteSuivi',{
                                        date:this.itemCamioForm.date,
                                        qte_charge:this.itemCamioForm.qte_boites,
                                        qte_decharge:0,
                                        user:this.itemCamioForm.user,
                                        bn_charge_id:this.idBon,
                                        boite_id:this.itemCamioForm.boites_id

                                            })
                                            .then(response => {

                                                this.isLoading=false;
                                                
                                            // console.log(response)

                                            this.itemsSuivieBoites.unshift(response.data.data);

                                                //afficher message
                                                this.flashMessage.show({
                                                status: 'success',
                                                title: 'Données sauvegardées',
                                                message: 'Les données sont sauvegardées correctement '
                                            })


                                            })
                                            .catch(error=> {console.log(error)
                                            this.isLoading=false;


                                                            this.flashMessage.show({
                                            status: 'error',
                                            title: 'Erreur De Sauvegarde',
                                            message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                            })
                                            });
                            }
                            else
                            {
                                            this.flashMessage.show({
                                            status: 'info',
                                            title: 'Vérifiez les données et Reésayez !!',
                                            })
                            }
        },
        ValiderBnCharge()
        {
                    if(this.idPrim >0)
                    {
                            this.isLoading=true;
                            this.$http.get('/detailPrimClient/'+this.idPrim)
                            .then((result) => {
                                        this.ListPrimesAriv = result.data.data
                                        this.isLoading=false;

                            })
                            .catch(error=> {
                                        console.log(error)
                                        this.isLoading=false;
                                        this.flashMessage.show({
                                        status: 'error',
                                        title: 'Erreur de Chargement',
                                        message: 'Impossible charger les données.. Actualisez et Réessayez'
                                        })
                            });
                    }
                    else
                    {
                        this.ListPrimesAriv=[]
                    }

                    //charger le suivi des boite
                    this.isLoading=true;
                    this.$http.get('/boites')
                    .then((result) => {
                                this.itemslistBoites = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

                    //charger le suivi des boite

                    this.isLoading=true;
                    this.$http.get('/boiteSuiviCharg/'+this.idBon)
                    .then((result) => {
                                this.itemsSuivieBoites = result.data.data
                                this.isLoading=false;

                                this.$root.$emit('bv::show::modal', 'ValidationModal')

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },

        exportDox()
        {

                    let datrw =[new TableRow({
                                children: [
                                    new TableCell({
                                        width: {
                                            size: 4000,
                                            type: WidthType.DXA,
                                        },
                                        verticalAlign: VerticalAlign.CENTER,
                                        children: [new Paragraph({
                                                    text: "Référence",
                                                            spacing: {
                                                            before: 100,
                                                            after: 100
                                                        },
                                                    heading: HeadingLevel.HEADING_3,
                                                    alignment: AlignmentType.CENTER,
                                                })],
                                    }),


                                    new TableCell({
                                        width: {
                                            size: 6000,
                                            type: WidthType.DXA,
                                        },
                                        verticalAlign: VerticalAlign.CENTER,
                                        children: [new Paragraph({
                                                    text: "Description",
                                                    heading: HeadingLevel.HEADING_3,
                                                    alignment: AlignmentType.CENTER,
                                                })],
                                    }),

                                    new TableCell({
                                        width: {
                                            size: 2500,
                                            type: WidthType.DXA,
                                        },
                                        verticalAlign: VerticalAlign.CENTER,
                                        children: [new Paragraph({
                                                    text: "Quantité",
                                                    heading: HeadingLevel.HEADING_3,
                                                    alignment: AlignmentType.CENTER,
                                                })],
                                    }),

                                    new TableCell({
                                        width: {
                                            size: 2500,
                                            type: WidthType.DXA,
                                        },
                                        verticalAlign: VerticalAlign.CENTER,
                                        children: [new Paragraph({
                                                    text: "Prix",
                                                    heading: HeadingLevel.HEADING_3,
                                                    alignment: AlignmentType.CENTER,
                                                })],
                                    }),

                                                    new TableCell({
                                        width: {
                                            size: 3000,
                                            type: WidthType.DXA,
                                        },
                                        verticalAlign: VerticalAlign.CENTER,
                                        children: [new Paragraph({
                                                    text: "Lot",
                                                    heading: HeadingLevel.HEADING_3,
                                                    alignment: AlignmentType.CENTER,
                                                })],
                                    }),



                                ],
                            })]

                    this.ArticlesList.forEach(function(item){

                    let r = new TableRow({
                                children: [
                                    new TableCell({
                                        width: {
                                            size: 4000,
                                            type: WidthType.DXA,
                                        },
                                        verticalAlign: VerticalAlign.CENTER,
                                        children: [new Paragraph({
                                                    text: item.ref,
                                                    spacing: {
                                                                before: 100,
                                                                after: 100,
                                                                left: 10,
                                                                right:10
                                                            },
                                                    heading: HeadingLevel.HEADING_6,
                                                    alignment: AlignmentType.CENTER,
                                                })],
                                    }),


                                    new TableCell({
                                        width: {
                                            size: 6000,
                                            type: WidthType.DXA,
                                        },
                                        verticalAlign: VerticalAlign.CENTER,
                                        children: [new Paragraph({
                                                    text: item.description,
                                                            spacing: {
                                                                before: 10,
                                                                after: 10,
                                                                left: 10,
                                                                right:10
                                                            },
                                                    heading: HeadingLevel.HEADING_6,
                                                    alignment: AlignmentType.CENTER,
                                                })],
                                    }),

                                    new TableCell({
                                        width: {
                                            size: 2500,
                                            type: WidthType.DXA,
                                        },
                                        verticalAlign: VerticalAlign.CENTER,
                                        children: [new Paragraph({
                                                    text: item.qte.toString(),

                                                    heading: HeadingLevel.HEADING_6,
                                                    alignment: AlignmentType.CENTER,
                                                })],
                                    }),

                                    new TableCell({
                                        width: {
                                            size: 2500,
                                            type: WidthType.DXA,
                                        },
                                        verticalAlign: VerticalAlign.CENTER,
                                        children: [new Paragraph({
                                                    text: item.prix.toString(),

                                                    heading: HeadingLevel.HEADING_6,
                                                    alignment: AlignmentType.CENTER,
                                                })],
                                    }),

                                    new TableCell({
                                        width: {
                                            size: 3000,
                                            type: WidthType.DXA,
                                        },
                                        verticalAlign: VerticalAlign.CENTER,
                                        children: [new Paragraph({
                                                    text: item.nom_lot,
                                                                                            spacing: {
                                                                before: 10,
                                                                after: 10,
                                                                left: 10,
                                                                right:10
                                                            },
                                                    heading: HeadingLevel.HEADING_6,
                                                    alignment: AlignmentType.CENTER,
                                                })],
                                    }),


                                ],
                            })

                    datrw.push(
                    r
                    )
                    });




        //tableau boites:
                                let datrwBoit =[new TableRow({
                                            children: [
                                                new TableCell({
                                                    width: {
                                                        size: 4500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "Type",
                                                                    spacing: {
                                                                    before: 100,
                                                                    after: 100
                                                                },
                                                                heading: HeadingLevel.HEADING_3,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),


                                                new TableCell({
                                                    width: {
                                                        size: 2500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "Qté Charge",
                                                                heading: HeadingLevel.HEADING_3,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),
                                                new TableCell({
                                                    width: {
                                                        size: 2500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "Qté Décharge",
                                                                heading: HeadingLevel.HEADING_3,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),

                                            ],
                                        })]

                                this.itemsSuivieBoites.forEach(function(itemr){

                                let rrr = new TableRow({
                                            children: [
                                                new TableCell({
                                                    width: {
                                                        size: 4500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: itemr.boite,
                                                                spacing: {
                                                                before: 50,
                                                                after: 50
                                                            },
                                                                heading: HeadingLevel.HEADING_6,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),


                                                new TableCell({
                                                    width: {
                                                        size: 3000,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: itemr.qte_charge.toString(),
                                                                heading: HeadingLevel.HEADING_6,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),

                                                new TableCell({
                                                    width: {
                                                        size: 3000,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "....",
                                                                heading: HeadingLevel.HEADING_6,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),

                                            ],
                                        })

                                datrwBoit.push(
                                rrr
                                )
                                });

        //fin tableau boites

        //tableau prime:
                                let datrwPrim =[new TableRow({
                                            children: [
                                                new TableCell({
                                                    width: {
                                                        size: 3500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "Client",
                                                                    spacing: {
                                                                    before: 100,
                                                                    after: 100
                                                                },
                                                                heading: HeadingLevel.HEADING_3,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),


                                                new TableCell({
                                                    width: {
                                                        size: 3500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "Réf",
                                                                heading: HeadingLevel.HEADING_3,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),
                                                new TableCell({
                                                    width: {
                                                        size: 2500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "Qté /Montant",
                                                                heading: HeadingLevel.HEADING_3,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),
                                                new TableCell({
                                                    width: {
                                                        size: 2500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "Lot",
                                                                heading: HeadingLevel.HEADING_3,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),

                                            ],
                                        })]

                                this.ListPrimesAriv.forEach(function(itemp){

                                let rrrp = new TableRow({
                                            children: [
                                                new TableCell({
                                                    width: {
                                                        size: 3500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: itemp.client,
                                                                spacing: {
                                                                before: 50,
                                                                after: 50
                                                            },
                                                                heading: HeadingLevel.HEADING_6,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),


                                                new TableCell({
                                                    width: {
                                                        size: 3500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: itemp.ref,
                                                                heading: HeadingLevel.HEADING_6,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),

                                                new TableCell({
                                                    width: {
                                                        size: 2500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: itemp.qte.toString(),
                                                                heading: HeadingLevel.HEADING_6,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),
                                                
                                                new TableCell({
                                                    width: {
                                                        size: 2500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: itemp.nom_lot,
                                                                heading: HeadingLevel.HEADING_6,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),

                                            ],
                                        })

                                datrwPrim.push(
                                rrrp
                                )
                                });

        //fin tableau prime


                    const doc = new Document();

                    const table = new Table({

                        rows:  datrw
                    })



                    const tableboite = new Table({

                        rows:  datrwBoit
                    })

                    const tableprim = new Table({

                        rows:  datrwPrim
                    })

                            doc.addSection({


                                properties: {
                                    page: {
                                        pageNumbers: {
                                            start: 1,
                                            formatType: PageNumberFormat.DECIMAL,
                                        },
                                    },
                                },
                                headers: {
                                    default: new Header({
                                        children: [
                                            new Paragraph({
                                                children: [
                                        new TextRun({
                                            text: "تعاونية حليب الريش",
                                            color: '636572',
                                            bold: true,

                                            })
                                                ],
                                            border: {
                                            bottom: {
                                                    color: "636572",
                                                    space: 4,
                                                    value: "single",
                                                    size: 10,
                                                }},
                                                heading: HeadingLevel.TITLE,
                                                alignment: AlignmentType.CENTER,
                                            }),
                                        ],
                                    }),
                                },
                                footers: {
                                    default: new Footer({
                                        children: [
                                            new Paragraph({
                                                alignment: AlignmentType.RIGHT,
                                                children: [
                                                    new TextRun({
                                                        children: ["Page : ", PageNumber.CURRENT],
                                                    }),
                                                    new TextRun({
                                                        children: [" Sur ", PageNumber.TOTAL_PAGES],
                                                    }),
                                                ],
                                            }),
                                        ],
                                    }),
                                },



                                children: [

                                    new Paragraph({



                                        children: [

                                            new TextRun({
                                            text: "Bon De Charge",
                                            color: '636572',
                                            bold: false,
                                            font: "Segoe UI"
                                        }),
                                        ],

                                        heading: HeadingLevel.HEADING_1,
                                                            alignment: AlignmentType.CENTER,

                                            spacing: {
                                                after: 200,
                                                before: 200
                                            },
                                    }),




                                    new Paragraph({



                                        children: [

                                            new TextRun({
                                            text: "N° : "+this.nomBon+"     Zone : "+this.nom_zon+"     Vendeur : "+this.nVend,
                                            color: '636572',
                                            bold: false,
                                            font: "Segoe UI"
                                        }),

                                            new TextRun({
                                            text: "          Dépôt : "+this.nDep,
                                            color: '636572',
                                            bold: false,
                                            font: "Segoe UI Light"
                                        })
                                        ],

                                        heading: HeadingLevel.HEADING_5,
                                        alignment: AlignmentType.LEFT,

                                            spacing: {
                                                after: 100,
                                                before: 100
                                            },
                                    }),
                                    
                                    

                                    new Paragraph({



                                        children: [
                                            new TextRun({
                                            text: "De la part de : ",
                                            color: '5d83a0',
                                            bold: false,
                                            font: "Segoe UI"
                                        }),
                                            new TextRun({
                                            text: this.user_name,
                                            color: '636572',
                                            bold: false,
                                            font: "Segoe UI Light"
                                        }),
                                        new TextRun({
                                            text: "          Date : ",
                                            color: '5d83a0',
                                            bold: false,
                                            font: "Segoe UI"
                                        }),

                                            new TextRun({
                                            text: this.dateToday,
                                            color: '636572',
                                            bold: false,
                                            font: "Segoe UI Light"
                                        })
                                        ],

                                        heading: HeadingLevel.HEADING_5,
                                                            alignment: AlignmentType.LEFT,

                                            spacing: {
                                                after: 300,
                                                before: 100
                                            },
                                    }),

                                    table,
                                                            new Paragraph({



                            children: [

                                new TextRun({
                                text: "Boites / Plateaux",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                            heading: HeadingLevel.HEADING_3,
                            alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 100,
                                    before: 100
                                },
                        }),
                        tableboite,
                            new Paragraph({

                                children: [
                                new TextRun({
                                text: "Primes Clients",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                            heading: HeadingLevel.HEADING_3,
                            alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 100,
                                    before: 100
                                },
                        }),
                        tableprim
                                    
                                    ]
                            })







                        Packer.toBlob(doc).then(blob => {

                    saveAs(blob, this.nomBon+".docx");

                        });

        },
        Addcmd_click(itm)
        {
                            this.isLoading=true;
                            this.$http.get('/articles/'+itm.article_id)
                            .then((result) => {
                
                    this.itemForm.ref=result.data.data.ref
                    this.itemForm.code_bar=result.data.data.code_bar
                    this.itemForm.qte=itm.qte
                    this.itemForm.description=result.data.data.description
                    this.itemForm.prix= result.data.data.prix_vent
                    this.itemForm.article_id=result.data.data.id         




                                        this.isLoading=false;



                    //rechercher lot:                   
                    this.fetchLots(itm.article_id)


                    this.$root.$emit('bv::hide::modal', 'cmdModal')

                            })
                            .catch(error=> {
                                        console.log(error)
                                        this.isLoading=false;
                                        this.flashMessage.show({
                                        status: 'error',
                                        title: 'Erreur de Chargement',
                                        message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                        })
                            });

        },
        cmdVend_click()
        {
        this.$root.$emit('bv::show::modal', 'cmdModal')

        },
        fetchCmdVendList: function (id_vndr) {
                    this.isLoading=true;
                    this.$http.get('/cmdVendeurLst/'+id_vndr)
                    .then((result) => {
                                this.ArticlesCmdVend = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        fetchCategorieList: function () {
                    this.isLoading=true;
                    this.$http.get('/categories')
                    .then((result) => {
                                this.listCat = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        cat_click(id_cat)
        {
                    this.isLoading=true;
                    this.$http.get('/articlesCat/'+id_cat)
                    .then((result) => {
                                this.listArt = result.data.data

                                
                                this.isLoading=false;


                                //effacer le search:
                                this.serchArt=''
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        art_click(id_art)
        {



            let art = this.listArt.findIndex(item => item.id === id_art)
            this.itemForm.ref=this.listArt[art].ref
            this.itemForm.code_bar=this.listArt[art].code_bar
            this.itemForm.qte=1
             this.itemForm.description=this.listArt[art].description
            this.itemForm.prix= this.listArt[art].prix_vent
            this.itemForm.article_id=this.listArt[art].id         

            this.fetchLots(id_art)
            
        },
        fetchLots(id_art) {
                    this.isLoading=true;
                    this.$http.get('/lotsArtDep/'+id_art+'/'+this.idDep)
                    .then((result) => {
                                this.ListLots = result.data.data
                                this.isLoading=false;


                                if(this.ListLots.length>0)
                                {
                                        this.itemForm.lot_id=this.ListLots[0].id
                                }
                                else
                                {
                                        this.itemForm.lot_id=''
                                }
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });

            //lot desti


        },
        fetchListArts(id_bn)
        {
                    this.isLoading=true;
                    this.$http.get('/bnChargeDetailItems/'+id_bn)
                    .then((result) => {
                                this.ArticlesList = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });


        },
        handleSubmit(){


                //set nom lot
                if(this.itemForm.lot_id)
                {
                let am = this.ListLots.findIndex(item => item.id === this.itemForm.lot_id)
                this.itemForm.nom_lot= this.ListLots[am].nom
                }



                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {

                            this.isLoading=true;

                            this.$http.post('/BnChargeDetail',{
                            ref:this.itemForm.ref,
                            qte:this.itemForm.qte,
                            description:this.itemForm.description,
                            nom_lot:this.itemForm.nom_lot,
                            lot_id:this.itemForm.lot_id,
                            prix:this.itemForm.prix,
                            qte_vend:0,
                            qte_retour:this.itemForm.qte,
                            article_id:this.itemForm.article_id,
                            bn_charge_id: this.idBon

                                })
                                .then(response => {

                                    this.isLoading=false;
                                    this.ArticlesList.unshift(response.data.data);

                                    //desactiver la validation:
                                    this.isSubmitted=false
                                    //afficher message
                                    this.flashMessage.show({
                                    status: 'success',
                                    title: 'Données sauvegardées',
                                    message: 'Les données sont sauvegardées correctement '
                                })


                                })
                                .catch(error=> {console.log(error)
                                this.isLoading=false;


                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur De Sauvegarde',
                                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                })
                                });
                           

                }

        },
        del_click(itemA){

            this.isLoading=true;

            this.$http.delete('/BnChargeDetail/'+itemA.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })
                    let index = this.ArticlesList.findIndex(item => item.id === itemA.id)

                    this.ArticlesList.splice(index, 1)
                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        NewBon()
        {
                    this.$router.push({ 
                        name: 'menuCharg'
                    });
        },
        del_BonClick() {

                                this.$confirm("Voulez-vous vraiment supprimer ces primes??")
                                .then(() => {

                                    this.BnDell(this.idBon)
                                })
                                .catch(error=> {
                                console.log(error)

                                    });




        },
        BnDell(id_bn){

            this.isLoading=true;

            this.$http.delete('/bnCharges/'+id_bn)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })

                //goto first page:
                    this.$router.push({ 
                        name: 'bonCharge'

                    });




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        AfficherTousArts()
        {
        this.cat_click(0)
        },
        ValhandleSubmit()
        {
                                this.$confirm("Voulez-vous vraiment VALIDER ce Bon de Charge ??")
                                .then(() => {

                                    this.validerStock(this.idBon,this.idPrim)
                                })
                                .catch(error=> {
                                console.log(error)

                                    });
        },
        validerStock(id_bn,id_prim)
        {

            this.isLoading=true;

            this.$http.get('/validCharg/'+id_bn+'/'+id_prim+'/1')
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Bon valider',
                message: 'Les données sont validées correctement'
                })

                //goto first page:

                    this.$router.push({ 
                        name: 'menuCharg'

                    });


                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Validation',
                message: 'Impossible  de valider les données .. Réessayez !!'
                })
                });

        },

        },
        components: {
                    Loading      
        }


}
</script>

<style>
/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #9cc7f9;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #9cc7f9;
}
::-webkit-scrollbar-thumb:active {
  background: #9cc7f9;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 60px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.BgSearch {
  background-color:#BEE5EB;
  opacity: 0.9;
}
</style>