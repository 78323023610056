<template>
<div>
  
               

      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center ">  

            <div class="col">
            <h4 class=" font-weight-lighter text-info ml-4 mt-3 mb-3 mr-2 float-left"> <span class="fa fa-boxes"></span> Stock </h4> 

                <b-button pill size="sm"  class="mt-3 mb-3 float-left" variant="outline-success" @click="add_click($event.target)">
                    <i class="fa fa-plus-square"></i>
                    <span> Ajou.</span> 
                </b-button>

                <b-button @click="exportDox" pill size="sm"  class="mt-3 mt-3 ml-2 float-left" variant="outline-info">
                     <i class="fa fa-print"></i>
                     <span> Imp.</span> 
                </b-button>

            </div>
            
            <div class="col">
                <div class="input-group col">
                                    <select class="form-control border border-ligth " id="selected_depot" v-model="selected_depot">
                                            <option v-for="item in depots" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>

                                     </select>


                                    <div class="input-group-prepend">
                                        <button @click="click_DepotSearch" class="btn  btn-light  border border-ligth rounded-right " type="submit" id="button-addon1">   
                                        <span class="fa fa-search"></span> Liste
                                        </button>
                                    </div>

                </div>
            </div>
                                
              <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Rechercher" class="col ml-4 mr-4"></b-form-input>




            </div>



        <b-table 
        id="main-table"

        sort-icon-left

        head-variant="light"
        :filter="filter"
        :fields="fields" 
        :items="itemlistStock"
        :per-page="perPage"
        :current-page="currentPage"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 mb-1  float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
                    <b-button pill size="sm" class="mr-2 mb-1 float-right" variant="outline-primary" @click="edit_click(row.item, row.index, $event.target)">
                            <i class="fa fa-pencil-alt"></i>
                    
                    </b-button>
                </template>
        
        </b-table>


                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>

      </div>


    <!-- le modal -->
    <b-modal id="banqModal" title="Ajouter / Modifier" size="lg" hide-footer>
           <form @submit.prevent="handleSubmit">

            <div class="row">
                

            <div class="form-group col">
                <label for="qte">Quantité :</label>
                <input type="text" v-model="boiteForm.qte" id="qte" name="qte" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.boiteForm.qte.$error }" />
                <div v-if="isSubmitted && !$v.boiteForm.qte.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.boiteForm.qte.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>
            </div>


            <div class="row">
                        <div class="form-group col">
                            <label for="centre">Type :</label>
                            <select class="form-control form-control-sm" id="centre" v-model="boiteForm.boite_id"
                            :class="{ 'is-invalid': isSubmitted && $v.boiteForm.boite_id.$error }">
                            <option v-for="item in boites" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>
                        </div>

                        <div class="form-group col">
                            <label for="depot">Dépôt :</label>
                            <select class="form-control form-control-sm" id="depot" v-model="boiteForm.depot_id"
                            :class="{ 'is-invalid': isSubmitted && $v.boiteForm.depot_id.$error }">
                            <option v-for="item in depots" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>
                            </select>
                        </div>

            </div>



                            <div class="form-group">
                                <button type="submit" class="btn mt-3 btn-primary btn-block">{{ModalType}}</button>
                            </div>

        </form>
    </b-modal>


    <!-- les trucs public -->

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {

    mounted(){


          this.fetchFormdepots()
    },

    computed: {
              user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    },
      rows_pagin() {
        return this.itemlistStock.length
      }
    },
	data () {
		return {
            selected_depot:'',
            filter: null,
            perPage: 10,
            currentPage: 1,
            depots:[],
            boites:[],
            itemlistStock: [],
            boiteForm: {
                    id:'',
                    qte:'',
                    boite_id:'',
                    depot_id:''
                    },
            fields: [
                {key: 'boite', label: 'Caisse/ Plateaux', sortable: true},
                {key: 'depot', label: 'Dépôt', sortable: true},
                {key: 'qte', label: 'Quantité', sortable: true},
                { key: 'actions', label: '' }
            ],
            //public trucs
            ModalEdit: false,
            ModalType:'Ajouter',
            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            boiteForm: {
                qte: {
                    required,
                    decimal
                },
                boite_id: {
                    required
                },
                depot_id: {
                    required
                }
            }
    },
    methods :{

    exportDox()
    {

        let cent = "--"

        if(this.selected_depot)
        {
                        let mm=this.depots.findIndex(item => item.id === this.selected_depot)

                        cent= this.depots[mm].nom
        }




                        let datrw =[new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 4600,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Caisse / Plateau",
                                                            spacing: {
                                                                before: 100,
                                                                after: 100
                                                            },
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),


                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Dépôt",
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Stock",
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                    ],
                                })]

                        this.itemlistStock.forEach(function(item){

                        let r = new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 4600,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: item.boite,
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),


                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: item.depot,
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: item.qte.toString(),
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                    ],
                                })

                        datrw.push(
                        r
                        )
                        });

                                const doc = new Document();

                        const table = new Table({

                            rows:  datrw
                        })



                                doc.addSection({


                                    properties: {
                                        page: {
                                            pageNumbers: {
                                                start: 1,
                                                formatType: PageNumberFormat.DECIMAL,
                                            },
                                        },
                                    },
                                    headers: {
                                        default: new Header({
                                            children: [
                                                new Paragraph({
                                                    children: [
                                            new TextRun({
                                                text: "تعاونية حليب الريش",
                                                color: '636572',
                                                bold: true,

                                                })
                                                    ],
                                                border: {
                                                bottom: {
                                                        color: "636572",
                                                        space: 4,
                                                        value: "single",
                                                        size: 10,
                                                    }},
                                                    heading: HeadingLevel.TITLE,
                                                    alignment: AlignmentType.CENTER,
                                                }),
                                            ],
                                        }),
                                    },
                                    footers: {
                                        default: new Footer({
                                            children: [
                                                new Paragraph({
                                                    alignment: AlignmentType.RIGHT,
                                                    children: [
                                                        new TextRun({
                                                            children: ["Page : ", PageNumber.CURRENT],
                                                        }),
                                                        new TextRun({
                                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                                        }),
                                                    ],
                                                }),
                                            ],
                                        }),
                                    },



                                    children: [

                                        new Paragraph({



                                            children: [

                                                new TextRun({
                                                text: "Stock Caisses /Plateaux",
                                                color: '636572',
                                                bold: false,
                                                font: "Segoe UI Light"
                                            }),

                                            ],

                                            heading: HeadingLevel.HEADING_2,
                                                                alignment: AlignmentType.CENTER,

                                                spacing: {
                                                    after: 300,
                                                    before: 300
                                                },
                                        }),
                                        
                                        
                                        new Paragraph({



                                            children: [

                                                new TextRun({
                                                text: "     Dépôt : "+cent,
                                                color: '636572',
                                                bold: false,
                                                font: "Segoe UI Light"
                                            })
                                            ],

                                            heading: HeadingLevel.HEADING_3,
                                                                alignment: AlignmentType.CENTER,

                                                spacing: {
                                                    after: 300,
                                                    before: 300
                                                },
                                        }),



                                        table
                                        
                                        ]
                                })







                            Packer.toBlob(doc).then(blob => {

                        saveAs(blob, "Stock_Caisse"+cent+".docx");

                            });

    },

        stockstore(){
           this.isLoading=true;


             this.$http.post('/stockCaisses',{
            qte:this.boiteForm.qte,
            depot_id:this.boiteForm.depot_id,
            boite_id:this.boiteForm.boite_id
                })
                .then(response => {

                    this.isLoading=false;
                    this.itemlistStock.unshift(response.data.data);
                    this.$bvModal.hide('banqModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        stockEdit(){

            this.isLoading=true;

            this.$http.patch('/stockCaisses/'+this.boiteForm.id,{
                qte:this.boiteForm.qte,
                depot_id:this.boiteForm.depot_id,
                boite_id:this.boiteForm.boite_id
                })
                .then(response => {

                    this.isLoading=false;
                    this.$bvModal.hide('banqModal')

                    let index = this.itemlistStock.findIndex(item => item.id === this.boiteForm.id)
                    this.itemlistStock.splice(index, 1,response.data.data)
                    
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })


                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

        },
        depotDell(){

            this.isLoading=true;

            this.$http.delete('/destroystKCaiss/'+this.boiteForm.id)
                .then(response => {
                console.log(response.data)
                    this.isLoading=false;



                    
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.itemlistStock.findIndex(item => item.id === this.boiteForm.id)
                    this.itemlistStock.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        add_click(button) {
        this.boiteForm.id='',
        this.boiteForm.qte=0,
        this.boiteForm.depot_id=this.selected_depot,
        this.boiteForm.boite_id=''


        this.ModalEdit=false;
        this.ModalType='Ajouter'

        this.$root.$emit('bv::show::modal', 'banqModal', button)
        },
        edit_click(item, index, button) {

                this.$confirm("Voulez-vous vraiment modifier cet élément ??")
                 .then(() => {

                this.boiteForm.id=item.id,
                this.boiteForm.qte=item.qte,
                this.boiteForm.depot_id=item.depot_id,
                this.boiteForm.boite_id=item.boite_id

                this.ModalEdit=true;
                this.ModalType='Modifier'

                this.$root.$emit('bv::show::modal', 'banqModal', button)
                })
                .catch(error=> {
                console.log(error)

                });


        },
            del_click(item) {

                    this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                    .then(() => {
                    this.boiteForm.id=item.id,




                    this.depotDell()
                                    })
                                    .catch(error=> {
                                    console.log(error)

                                        });

        },

        click_DepotSearch: function () {
                        if(this.selected_depot)
                        {
                                            this.isLoading=true;
                                            this.$http.get('/stockBoiteDep/'+this.selected_depot)
                                            .then((result) => {
                                                        this.itemlistStock = result.data.data
                                                        this.isLoading=false;

                                                    
                                            })
                                            .catch(error=> {
                                                        console.log(error)
                                                        this.isLoading=false;
                                                        this.flashMessage.show({
                                                        status: 'error',
                                                        title: 'Erreur de Chargement',
                                                        message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                                        })
                                            });
                        }
                        else
                        {
                                        this.flashMessage.show({
                                        status: 'info',
                                        title: 'Sélectionez un dépôt et Reésayez !!',
                                        })
                        }
        },
       
        fetchFormdepots: function () {
                    this.isLoading=true;
                    this.$http.get('/depotPerson/'+this.user_dep)
                    .then((result) => {
                                this.depots = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });

                    //chager les boites:
                    this.isLoading=true;
                    this.$http.get('/boites')
                    .then((result) => {
                                this.boites = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
       
       handleSubmit() {
                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                            if(this.ModalEdit)
                            {
                                this.stockEdit()
                            }
                            else
                            {

                                this.stockstore()
                            }   

                }

            }

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>