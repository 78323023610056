<template>
<div class="p-4 back_princ">


<div v-if="d_gst_cam_boit==true"> <!-- partie camion --> 


<div class=" d-flex justify-content-center text-info">
 <i class="fas fa-truck-moving fa-2x"></i>
</div>

<div class=" d-flex justify-content-center text-info mb-2">
 <h4 class="font-weight-lighter">Camions</h4>
</div>



<div class=" row m-2">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_camion">
                <div class="card-body">
                  <div class="row  no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Listes Des Camions</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-truck-moving fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

</div>



<div class=" row m-2">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_gazoil">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="font-weight-bold text-secondary  text-uppercase ">Km / Gazoil</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-gas-pump fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Earnings (Annual) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2"  @click="click_maintenance">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary  text-uppercase">Maintenance</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-tools fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

</div>




</div> <!-- Fin partie camion --> 

<div v-if="d_gst_boit==true"> <!-- partie plateaux --> 



<div class=" d-flex justify-content-center text-info mt-5">
 <i class="fas fa-inbox fa-2x"></i>
</div>

<div class=" d-flex justify-content-center text-info mb-2">
 <h4 class="font-weight-lighter">Caisses / Plateaux</h4>
</div>



<div class=" row m-2">

            <!-- Earnings (Monthly) Card Example --> 
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_boites">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Caisses / Plateaux</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-inbox fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

                        <!-- Earnings (Monthly) Card Example --> 
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_boites_stock">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase"> Stock </div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-boxes fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>


</div>

<div class=" row m-2">

            
<!-- Earnings (Monthly) Card Example --> 
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_boites_stockCasse">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase"> Casse / Perte</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-dumpster fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

<!-- Earnings (Monthly) Card Example --> 
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_deplac_caisse">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase"> Déplacements</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-truck-loading fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
</div>


</div> <!-- Fin partie plateaux --> 

    <!-- le modal -->
    <b-modal id="depotModal" title="Sélectionnez Les Dépôts"  hide-footer>
           <form @submit.prevent="submitDepot_deplac">

                        <div class="form-group">
                            <label for="depot_id">Dépôt Source :</label>
                            <select  class="form-control" id="depot_id" v-model="itemFormBon.depot_sourc_id">
                            <option v-for="item in depots" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>
                        </div>


                        <div class="form-group">

                            <label for="depot_destin_id">Dépôt Destination :</label>
                            <select class="form-control" id="depot_destin_id" v-model="itemFormBon.depot_destin_id">
                            <option v-for="item in depots" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>

                        </div>


            <div class="form-group mt-2">
                <button type="submit" class="btn  btn-primary btn-block"> Valider</button>
            </div>


        </form>
    </b-modal>


    <!-- le modal -->

    <!-- le modal -->
    <b-modal id="casseModal" title="Sélectionnez Le Dépôt"  hide-footer>
           <form @submit.prevent="submitDepot_casse">

                        <div class="form-group">
                            <label for="depot_id">Dépôt  :</label>
                            <select  class="form-control" id="depot_id" v-model="itemFormBon.depot_sourc_id">
                            <option v-for="item in depots" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>
                        </div>



            <div class="form-group mt-2">
                <button type="submit" class="btn  btn-primary btn-block"> Valider</button>
            </div>


        </form>
    </b-modal>


    <!-- le modal -->




      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>



  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  
    computed: {
      user_dep() {
      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    },
    d_gst_cam_boit() {
      return this.$store.getters.get_gst_cam_boit;
    },
    d_gst_boit() {
      return this.$store.getters.get_gst_boit;
    },
    },
data () {
		return {

            depots:[],
            itemFormBon: {
                    depot_sourc_id:'',
                    depot_destin_id:''},

            isSubmitted: false,
            isLoading: false,
            isModification:false,

		}
    },
 methods:{
       click_camion()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'listCamion'

                    });
      },
      click_gazoil()
      {
                       //goto first page:
                    this.$router.push({ 
                        name: 'listKilomCamion'

                    });
      },
      click_maintenance()
      {
                        //goto first page:
                    this.$router.push({ 
                        name: 'maitenances'

                    });
      },
      click_boites()
      {
                        //goto first page:
                    this.$router.push({ 
                        name: 'boites'

                    });
      },
      click_boites_stock()
      {
                        //goto first page:
                    this.$router.push({ 
                        name: 'stockBoites'

                    });
      },
      click_boites_stockCasse()
      {
                    //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/depotPerson/'+this.user_dep)
                    .then((result) => {
                                this.depots = result.data.data
                                this.isLoading=false;

                                this.$root.$emit('bv::show::modal', 'casseModal')
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });
      },
      click_deplac_caisse()
      {
                            //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/depots')
                    .then((result) => {
                                this.depots = result.data.data
                                this.isLoading=false;

                                this.$root.$emit('bv::show::modal', 'depotModal')
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });
      },
      submitDepot_deplac()
          {
                          if(!this.itemFormBon.depot_sourc_id || !this.itemFormBon.depot_destin_id)
                          {
                                this.flashMessage.show({
                                status: 'info',
                                title: 'Vérifiez les données et Reésayez !!',
                                })
                          }
                          else
                          {
                           
                                if(this.itemFormBon.depot_sourc_id==this.itemFormBon.depot_destin_id)
                                {
                                        this.itemFormBon.depot_sourc_id=''
                                        this.itemFormBon.depot_destin_id=''

                                  this.flashMessage.show({
                                  status: 'info',
                                  title: 'Sélectionnez deux dépôts différents !!',
                                  })

                                }
                                else
                                {

                                  let src = this.depots.findIndex(item => item.id === this.itemFormBon.depot_sourc_id)
                                  let dest = this.depots.findIndex(item => item.id === this.itemFormBon.depot_destin_id)

                                  this.$router.push({ 
                                  name: 'stockCaisseDeplac', 
                                  params: { 
                                  idDepS:this.itemFormBon.depot_sourc_id,
                                  idDepD:this.itemFormBon.depot_destin_id,
                                  Nsouc:this.depots[src].nom,
                                  Ndestin:this.depots[dest].nom
                                  } 
                                  });


                                }

                          }

          },
                submitDepot_casse()
          {
                          if(!this.itemFormBon.depot_sourc_id)
                          {
                                this.flashMessage.show({
                                status: 'info',
                                title: 'Vérifiez les données et Reésayez !!',
                                })
                          }
                          else
                          {
                           

                                  let src = this.depots.findIndex(item => item.id === this.itemFormBon.depot_sourc_id)

                                  this.$router.push({ 
                                  name: 'stockBoiteCasse', 
                                  params: { 
                                  idDepS:this.itemFormBon.depot_sourc_id,
                                  Nsouc:this.depots[src].nom,

                                  } 
                                  });

                          }

          }


 },
 components: {
              Loading      
}
}
</script>
<style scoped>
.back_princ{
  background: #eff7fa;
}

.text_org{
  color: green;
}
</style>