<template>
<div>
             
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">   

                            <h4 class="col ml-3 font-weight-lighter text-info"> <span class="fa fa-gas-pump"></span> Km / Gazoil</h4> 

<div class="col">
                <b-button pill size="sm"  class="m-3 float-right" variant="success" @click="add_click($event.target)">
                            <i class="fa fa-plus-square"></i>
                    <span> Ajouter</span> 
                </b-button>

                <b-button pill size="sm"  class="m-3 float-right" variant="info" @click="fetchList">
                    <i class="fa fa-sync-alt"></i>
                    <span> Actualiser</span> 
                </b-button>
</div>
            </div>
       



        <b-table 
        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"

        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
                    <b-button pill size="sm" class="mr-2 float-right" variant="outline-primary" @click="edit_click(row.item, row.index, $event.target)">
                            <i class="fa fa-pencil-alt"></i>
                    
                    </b-button>
                </template>
        
        </b-table>

                        <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>
      </div>


    <!-- le modal -->
    <b-modal id="banqModal" :title="itemForm.date"  hide-footer>
           <form @submit.prevent="handleSubmit">

            <div class="form-group">
                <label for="date">Date</label>
                    <b-form-datepicker
                    id="datepicker-dateformat2"
                    placeholder="Choose a date"
                    v-model="itemForm.date"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.date.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.date.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>

            <div class="form-group ">

                            <label for="camion_id">Camion :</label>
                            <select class="form-control" id="camion_id" v-model="itemForm.camion_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.camion_id.$error }">
                            <option v-for="item in camionsList" :key="item.id" v-bind:value="item.id">   
                                {{ item.matricul }}
                            </option>

                            </select>

                             <div v-if="isSubmitted && !$v.itemForm.camion_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

            </div>

            <div class="form-group">
                <label for="kilom_depart">Km Départ :</label>
                <input type="text" v-model="itemForm.kilom_depart" id="kilom_depart" name="kilom_depart" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.kilom_depart.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.kilom_depart.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.kilom_depart.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>


            <div class="form-group">
                <label for="kilom_arrive">Km Arrivé :</label>
                <input type="text" v-model="itemForm.kilom_arrive" id="kilom_arrive" name="kilom_arrive" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.kilom_arrive.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.kilom_arrive.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.kilom_arrive.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>


            <div class="form-group">
                <label for="carbur_l">Gazoil ( L ) :</label>
                <input type="text" v-model="itemForm.carbur_l" id="carbur_l" name="carbur_l" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.carbur_l.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.carbur_l.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.carbur_l.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>



            <div class="form-group">
                <label for="carbur_dh">Gazoil ( Dhs ) :</label>
                <input type="text" v-model="itemForm.carbur_dh" id="carbur_dh" name="carbur_dh" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.carbur_dh.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.carbur_dh.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.carbur_dh.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>


            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
            </div>


        </form>
    </b-modal>


    <!-- les trucs public -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";

export default {
      computed: {

      rows_pagin() {
        return this.itemslist.length
      }
      },
    mounted(){
      //fetch les vendeurs :
      this.fetchCamionsEtListe()
    },
	data () {
		return {
            perPage: 10,
            currentPage: 1,
            itemslist: [],
            itemForm: {
                    id:'',
                    date: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    kilom_depart:'',
                    kilom_arrive:'',
                    carbur_l:'',
                    carbur_dh:'',
                    camion_id:'',
                    charge_id:0},
            camionsList:[],
            fields: [
                {key: 'matricul', label: 'Camion', sortable: true},
				{key: 'date', label: 'Date', sortable: true},
                {key: 'kilom_depart', label: 'Km depart', sortable: true},
                {key: 'kilom_arrive', label: 'Km Arrivé', sortable: true},
                {key: 'carbur_l', label: 'Gazoil (L)', sortable: true},
                {key: 'carbur_dh', label: 'Gazoil (DHs)', sortable: true},
                { key: 'actions', label: '' }
            ],
            //public trucs 
            ModalEdit: false,
            ModalType:'Ajouter',
            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemForm: {
                date: {
                    required
                },
                kilom_depart: {
                    required,
                    decimal
                },
                kilom_arrive: {
                    required,
                    decimal
                },
                carbur_l: {
                    required,
                    decimal
                },
                carbur_dh: {
                    required,
                    decimal
                },
                charge_id: {
                    required
                },
                camion_id: {
                    required
                }
            }
    },
    methods :{


        itemslisttore(){
                        this.isLoading=true;

            this.$http.post('/kilomCamion',{
            date:this.itemForm.date,
            kilom_depart:this.itemForm.kilom_depart,
            kilom_arrive:this.itemForm.kilom_arrive,
            carbur_l:this.itemForm.carbur_l,
            carbur_dh:this.itemForm.carbur_dh,
            charge_id:this.itemForm.charge_id,
            camion_id:this.itemForm.camion_id

                })
                .then(response => {

                    this.isLoading=false;
                    this.itemslist.unshift(response.data.data);
                    this.$bvModal.hide('banqModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        banqueEdit(){

            this.isLoading=true;

            this.$http.patch('/kilomCamion/'+this.itemForm.id,{
            date:this.itemForm.date,
            kilom_depart:this.itemForm.kilom_depart,
            kilom_arrive:this.itemForm.kilom_arrive,
            carbur_l:this.itemForm.carbur_l,
            carbur_dh:this.itemForm.carbur_dh,
            charge_id:this.itemForm.charge_id,
            camion_id:this.itemForm.camion_id
                })
                .then(response => {

                    this.isLoading=false;
                    console.log(response.data)
                    this.$bvModal.hide('banqModal')

                    let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                    this.itemslist.splice(index, 1,response.data.data)
                    
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })


                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

        },
        banqueDell(){

            this.isLoading=true;

            this.$http.delete('/kilomCamion/'+this.itemForm.id)
                .then(response => {
                console.log(response.data)
                    this.isLoading=false;



                    
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                    this.itemslist.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        add_click(button) {
        this.itemForm.id=''
        this.itemForm.date= new Date().toJSON().slice(0,10).replace(/-/g,'-')
        this.itemForm.kilom_depart=''
        this.itemForm.kilom_arrive=''
        this.itemForm.carbur_l=''
        this.itemForm.carbur_dh=''
        this.itemForm.charge_id=0
        this.itemForm.camion_id=''

        this.ModalEdit=false;
        this.ModalType='Ajouter'

        this.$root.$emit('bv::show::modal', 'banqModal', button)
        },
        edit_click(item, index, button) {

                this.$confirm("Voulez-vous vraiment modifier cet élément ??")
                 .then(() => {
                this.itemForm.id=item.id
                this.itemForm.date=item.date
                this.itemForm.kilom_depart=item.kilom_depart
                this.itemForm.kilom_arrive=item.kilom_arrive
                this.itemForm.carbur_l=item.carbur_l
                this.itemForm.carbur_dh=item.carbur_dh
                this.itemForm.charge_id=item.charge_id
                this.itemForm.camion_id=item.camion_id

                this.ModalEdit=true;
                this.ModalType='Modifier'

                this.$root.$emit('bv::show::modal', 'banqModal', button)
                })
                .catch(error=> {
                console.log(error)

                });


        },
        del_click(item) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {
                this.itemForm.id=item.id
                this.itemForm.date=item.date
                this.itemForm.kilom_depart=item.kilom_depart
                this.itemForm.kilom_arrive=item.kilom_arrive
                this.itemForm.carbur_l=item.carbur_l
                this.itemForm.carbur_dh=item.carbur_dh
                this.itemForm.charge_id=item.charge_id
                this.itemForm.camion_id=item.camion_id

                this.banqueDell()
                                })
                                .catch(error=> {
                                console.log(error)

                                    });




        },
        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/kilomCamion')
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        handleSubmit() {
                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                            if(this.ModalEdit)
                            {
                                this.banqueEdit()
                            }
                            else
                            {

                                this.itemslisttore()
                            }   

                }

        },
        fetchCamionsEtListe: function () {
                    //chager les categories:
                    this.isLoading=true;
                    this.$http.get('/camions')
                    .then((result) => {
                                this.camionsList = result.data.data
                                this.isLoading=false;
                                //list
                                this.fetchList()
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        }

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>