<template>
<div>
  
                        

      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    


            <h4 class="col m-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Bons De Charge ( Validés )    </h4> 
  
                     <div class="col">
                                        <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                            <i class="fa fa-home fa-2x"></i>
                </b-button>


                                    <b-button pill size="sm"  class="m-2 float-right" variant="info" @click="fetchList">
                                        <i class="fa fa-sync-alt"></i>
                                        <span> Actualiser</span> 
                                    </b-button>
                    </div>

            </div>
       



        <b-table 

        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"

        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
            <template #cell(actions)="row">
                    <b-button  v-if="d_gst_charg_val==true" pill size="sm"  class="m-1 float-right" variant="outline-warning" @click="devald_click(row.item)">
                                <i class="fa fa-check"></i>
                        
                    </b-button>


                    <b-button v-if="d_gst_charg_vent==true" pill size="sm" class="m-1 float-right" variant="outline-success" @click="ventes_click(row.item)">
                            <i class="fa fa-file-export"></i>
                    
                    </b-button>

                   <b-button v-if="d_gst_charg_vent==true" pill size="sm" class="m-1 float-right" variant="outline-info" @click="Ckick_exportDox(row.item)">
                            <i class="fa fa-print"></i>
                    
                    </b-button>

                    <b-button v-if="d_gst_trajet_vend==true" pill size="sm" class="m-1 float-right" variant="outline-secondary" @click="trajet_click(row.item)">
                            <i class="fa fa-map-marked-alt"></i>
                    
                    </b-button>
                </template>
        
        </b-table>


                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>

      </div>




    <!-- les trucs public -->

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
 import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
 import { saveAs } from 'file-saver';

export default {
    computed: {
        
      rows_pagin() {
        return this.itemslist.length
      },
    d_gst_charg_val() {
      return this.$store.getters.get_gst_charg_val;
    },
        d_gst_charg_vent() {
      return this.$store.getters.get_gst_charg_vent;
    },
        d_gst_trajet_vend() {
      return this.$store.getters.get_gst_trajet_vend;
    },
    user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    },
    },
    mounted(){
        this.fetchList()
    },
	data () {
		return {
            itemsSuivieBoites:[],
            perPage: 20,
            currentPage: 1,
            itemslist: [],
            ArticlesList: [],
            ListPrimesAriv:[],
            fields: [
				{key: 'nom', label: 'Nom', sortable: true},
                {key: 'date', label: 'Date', sortable: true},
                {key: 'zone', label: 'Zone', sortable: true},
                {key: 'vendeur', label: 'Vendeur', sortable: true},
                {key: 'camion', label: 'Camion', sortable: true},
                {key: 'depot', label: 'Dépôt', sortable: true},
                 {key: 'user', label: 'Utilisateur', sortable: true},
                { key: 'actions', label: '' }
            ],
            //public trucs

            isLoading: false
		}
	},
    validations: {
            itemForm: {
                nom: {
                    required
                }
            }
    },
    methods :{
                Ckick_exportDox(rowitem)
{


                    if(rowitem.prim_client_id >0)
                    {
                            this.isLoading=true;
                            this.$http.get('/detailPrimClient/'+rowitem.prim_client_id)
                            .then((result) => {
                                        this.ListPrimesAriv = result.data.data
                                        this.isLoading=false;

                            })
                            .catch(error=> {
                                        console.log(error)
                                        this.isLoading=false;
                                        this.flashMessage.show({
                                        status: 'error',
                                        title: 'Erreur de Chargement',
                                        message: 'Impossible charger les données.. Actualisez et Réessayez'
                                        })
                            });
                    }
                    else
                    {
                        this.ListPrimesAriv=[]
                    }


//charger le suivie :
                    this.isLoading=true;
                    this.$http.get('/boiteSuiviCharg/'+rowitem.id)
                    .then((result) => {
                                this.itemsSuivieBoites = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });



                    this.isLoading=true;
                    this.$http.get('/bnChargeDetailItems/'+rowitem.id)
                    .then((result) => {
                                this.ArticlesList = result.data.data
                                this.isLoading=false;


                                //telecharger rapp
                                this.exportDox(rowitem)

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
},
        exportDox(rowitem)
{

let datrw =[new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 4000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: "Référence",
                                        spacing: {
                                        before: 100,
                                        after: 100
                                    },
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),


                new TableCell({
                    width: {
                        size: 6000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: "Description",
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                 new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: "Quantité",
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: "Prix",
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                                new TableCell({
                    width: {
                        size: 3000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: "Lot",
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),



            ],
        })]

this.ArticlesList.forEach(function(item){

let r = new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 4000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: item.ref,
                                  spacing: {
                                            before: 100,
                                            after: 100,
                                            left: 10,
                                            right:10
                                        },
                                  heading: HeadingLevel.HEADING_6,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),


                new TableCell({
                    width: {
                        size: 6000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: item.description,
                                        spacing: {
                                            before: 10,
                                            after: 10,
                                            left: 10,
                                            right:10
                                        },
                                  heading: HeadingLevel.HEADING_6,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                 new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: item.qte.toString(),

                                  heading: HeadingLevel.HEADING_6,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                 new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: item.prix.toString(),

                                  heading: HeadingLevel.HEADING_6,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                new TableCell({
                    width: {
                        size: 3000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: item.nom_lot,
                                                                          spacing: {
                                            before: 10,
                                            after: 10,
                                            left: 10,
                                            right:10
                                        },
                                  heading: HeadingLevel.HEADING_6,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),


            ],
        })

  datrw.push(
 r
)
});

        const doc = new Document();

const table = new Table({

    rows:  datrw
})



        //tableau boites:
                                let datrwBoit =[new TableRow({
                                            children: [
                                                new TableCell({
                                                    width: {
                                                        size: 4500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "Type",
                                                                    spacing: {
                                                                    before: 100,
                                                                    after: 100
                                                                },
                                                                heading: HeadingLevel.HEADING_3,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),


                                                new TableCell({
                                                    width: {
                                                        size: 2500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "Qté Charge",
                                                                heading: HeadingLevel.HEADING_3,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),
                                                new TableCell({
                                                    width: {
                                                        size: 2500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "Qté Décharge",
                                                                heading: HeadingLevel.HEADING_3,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),

                                            ],
                                        })]

                                this.itemsSuivieBoites.forEach(function(itemr){

                                let rrr = new TableRow({
                                            children: [
                                                new TableCell({
                                                    width: {
                                                        size: 4500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: itemr.boite,
                                                                spacing: {
                                                                before: 50,
                                                                after: 50
                                                            },
                                                                heading: HeadingLevel.HEADING_6,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),


                                                new TableCell({
                                                    width: {
                                                        size: 3000,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: itemr.qte_charge.toString(),
                                                                heading: HeadingLevel.HEADING_6,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),

                                                new TableCell({
                                                    width: {
                                                        size: 3000,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "...",
                                                                heading: HeadingLevel.HEADING_6,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),

                                            ],
                                        })

                                datrwBoit.push(
                                rrr
                                )
                                });
        const tableboite = new Table({

            rows:  datrwBoit
        })
        //fin tableau boites


        //tableau prime:
                                let datrwPrim =[new TableRow({
                                            children: [
                                                new TableCell({
                                                    width: {
                                                        size: 3500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "Client",
                                                                    spacing: {
                                                                    before: 100,
                                                                    after: 100
                                                                },
                                                                heading: HeadingLevel.HEADING_3,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),


                                                new TableCell({
                                                    width: {
                                                        size: 3500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "Réf",
                                                                heading: HeadingLevel.HEADING_3,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),
                                                new TableCell({
                                                    width: {
                                                        size: 2500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "Qté /Montant",
                                                                heading: HeadingLevel.HEADING_3,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),
                                                new TableCell({
                                                    width: {
                                                        size: 2500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: "Lot",
                                                                heading: HeadingLevel.HEADING_3,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),

                                            ],
                                        })]

                                this.ListPrimesAriv.forEach(function(itemp){

                                let rrrp = new TableRow({
                                            children: [
                                                new TableCell({
                                                    width: {
                                                        size: 3500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: itemp.client,
                                                                spacing: {
                                                                before: 50,
                                                                after: 50
                                                            },
                                                                heading: HeadingLevel.HEADING_6,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),


                                                new TableCell({
                                                    width: {
                                                        size: 3500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: itemp.ref,
                                                                heading: HeadingLevel.HEADING_6,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),

                                                new TableCell({
                                                    width: {
                                                        size: 2500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: itemp.qte.toString(),
                                                                heading: HeadingLevel.HEADING_6,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),
                                                
                                                new TableCell({
                                                    width: {
                                                        size: 2500,
                                                        type: WidthType.DXA,
                                                    },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    children: [new Paragraph({
                                                                text: itemp.nom_lot,
                                                                heading: HeadingLevel.HEADING_6,
                                                                alignment: AlignmentType.CENTER,
                                                            })],
                                                }),

                                            ],
                                        })

                                datrwPrim.push(
                                rrrp
                                )
                                });

        //fin tableau prime



                    const tableprim = new Table({

                        rows:  datrwPrim
                    })



        doc.addSection({


            properties: {
                page: {
                    pageNumbers: {
                        start: 1,
                        formatType: PageNumberFormat.DECIMAL,
                    },
                },
            },
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({
                            children: [
                    new TextRun({
                        text: "تعاونية حليب الريش",
                        color: '636572',
                        bold: true,

                        })
                            ],
                        border: {
                           bottom: {
                                color: "636572",
                                space: 4,
                                value: "single",
                                size: 10,
                            }},
                            heading: HeadingLevel.TITLE,
                            alignment: AlignmentType.CENTER,
                        }),
                    ],
                }),
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.RIGHT,
                            children: [
                                new TextRun({
                                    children: ["Page : ", PageNumber.CURRENT],
                                }),
                                new TextRun({
                                    children: [" Sur ", PageNumber.TOTAL_PAGES],
                                }),
                            ],
                        }),
                    ],
                }),
            },



            children: [

                new Paragraph({



                    children: [

                        new TextRun({
                        text: "Bon De Charge",
                        color: '636572',
                        bold: false,
                        font: "Segoe UI"
                    }),
                    ],

                    heading: HeadingLevel.HEADING_1,
                                        alignment: AlignmentType.CENTER,

                        spacing: {
                            after: 200,
                            before: 200
                        },
                }),




                new Paragraph({



                    children: [

                        new TextRun({
                        text: "N° : "+rowitem.nom+"     Zone : "+rowitem.zone+"     Vendeur : "+rowitem.vendeur,
                        color: '636572',
                        bold: false,
                        font: "Segoe UI"
                    }),

                        new TextRun({
                        text: "          Dépôt : "+rowitem.depot,
                        color: '636572',
                        bold: false,
                        font: "Segoe UI Light"
                    })
                    ],

                    heading: HeadingLevel.HEADING_5,
                    alignment: AlignmentType.LEFT,

                        spacing: {
                            after: 100,
                            before: 100
                        },
                }),
                
                

                new Paragraph({



                    children: [
                        new TextRun({
                        text: "De la part de : ",
                        color: '5d83a0',
                        bold: false,
                        font: "Segoe UI"
                    }),
                        new TextRun({
                        text: rowitem.user,
                        color: '636572',
                        bold: false,
                        font: "Segoe UI Light"
                    }),
                    new TextRun({
                        text: "          Date : ",
                        color: '5d83a0',
                        bold: false,
                        font: "Segoe UI"
                    }),

                        new TextRun({
                        text: rowitem.date,
                        color: '636572',
                        bold: false,
                        font: "Segoe UI Light"
                    })
                    ],

                    heading: HeadingLevel.HEADING_5,
                                        alignment: AlignmentType.LEFT,

                        spacing: {
                            after: 300,
                            before: 100
                        },
                }),

                table,

                
                        new Paragraph({


                            children: [

                                new TextRun({
                                text: "Boites / Plateaux",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                            heading: HeadingLevel.HEADING_2,
                            alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 100,
                                    before: 100
                                },
                        }),
                        tableboite,
                        new Paragraph({

                                children: [
                                new TextRun({
                                text: "Primes Clients",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                            heading: HeadingLevel.HEADING_3,
                            alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 100,
                                    before: 100
                                },
                        }),
                        tableprim
                
                ]
        })

    Packer.toBlob(doc).then(blob => {

  saveAs(blob, rowitem.nom+".docx");

    });

},
        NewBon()
        {
                    this.$router.push({ 
                        name: 'menuCharg'
                    });
        },

              ventes_click(item) {

                this.$confirm("Voulez-vous vraiment Afficher les ventes de ce Bon / Valider ce Bon ??")
                 .then(() => {

                        this.$router.push({ 
                        name: 'bonDecharge', 
                        params: { 
                        idZon: item.zone_id,
                        nZon: item.zone,
                        idCharg: item.id,
                        nCharg: item.nom,
                        nVend: item.vendeur,
                        id_Vend: item.vendeur_id,
                        idPrime:item.prim_client_id,
                        is_val:true,
                        is_stockval:item.stock_val            
                        } 
                    });

                })
                .catch(error=> {
                console.log(error)

                });


      },
      
      trajet_click(item) {

                this.$confirm("Voulez-vous vraiment Afficher le trajet ??")
                 .then(() => {

                        this.$router.push({ 
                        name: 'trajetVend', 
                        params: { 
                        nZon: item.zone,
                        idCharg: item.id,
                        nCharg: item.nom,
                        nVend: item.vendeur          
                        } 
                    });

                })
                .catch(error=> {
                console.log(error)

                });


      },

        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/bnChargeListType/1/0/'+this.user_dep)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        devald_click(item) {

                                this.$confirm("Voulez-vous vraiment Devalider ce Bon??")
                                .then(() => {

                                    this.BnDevalider(item.id,item.prim_client_id)
                                })
                                .catch(error=> {
                                console.log(error)

                                    });




      },

        BnDevalider(id_bn,idprim){

            this.isLoading=true;

            this.$http.get('/validCharg/'+id_bn+'/'+idprim+'/0')
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Bon Devalidé',
                message: 'Les données sont devalidées correctement'
                })

                                    let index = this.itemslist.findIndex(item => item.id === id_bn)
                                    this.itemslist.splice(index, 1)





                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de devalider les données .. Réessayez !!'
                })
                });

        },

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>