<template>
  

<div  class="rounded-lg shadow border border-white bg-white h-100  pb-5">



        <div class="row pb-1 border-bottom border-info m-3">


                            <h4 class="col  mt-3  font-weight-lighter text-info"> <span class="fa fa-prescription-bottle-alt"></span> Maintenance Des Camions     </h4> 
            
        </div>

          <div class="row mt-5 mb-5">

        <div class="col-md-8 ml-auto mr-auto">

           <form @submit.prevent="handleSubmit()">

            <div class="row mt-2">


                         <div class="form-group col">

                            <label for="camion_id">Camion :</label>
                            <select class="form-control" id="camion_id" v-model="itemForm.camion_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.camion_id.$error }">
                            <option v-for="item in camions" :key="item.id" v-bind:value="item.id">   
                                {{ item.matricul }}
                            </option>

                            </select>

                             <div v-if="isSubmitted && !$v.itemForm.camion_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                         </div>
            </div>




            <div class="row mt-2 ">


                         <div class="form-group col">



                <div class="float-left pt-1">
                     <label for="garage_id">Garage :</label>
                </div>
                <b-button  pill   class="ml-3 p-0 mt-1 float-left text-success" variant="link" @click="add_garag_click()">
                            <i class="fa fa-plus-square"></i>

                </b-button>

                           
                            <select class="form-control" id="garage_id" v-model="itemForm.garage_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.garage_id.$error }">
                            <option v-for="item in garages" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>

                             <div v-if="isSubmitted && !$v.itemForm.garage_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>




                         </div>



            </div>


            <div class="form-group mt-5">
                <button type="submit" class="btn  btn-primary btn-block">Suivant</button>
            </div>

        </form>

        </div>   

</div> 




 <b-modal  id="catmodal" title="Garages"  hide-footer>

        <form @submit.prevent="handleSubmitCatg">

                            <div class="form-group">
                                <label for="currentType">Garage :</label>
                                <input type="text" v-model="currentType" id="currentType" name="currentType" class="form-control" />

                            </div>
            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Ajouter</button>
            </div>

        </form>



        <b-table 
        sort-icon-left
        small 
        sticky-header
        :fields="fildegarg" 
        :items="garages"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_garag_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
            </template>
        
        </b-table>


</b-modal>
    <!-- les trucs public -->




    
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";

export default {

mounted(){

this.fetchCentres()


    },
data () {
		return {

            camions:[],
            garages:[],
            currentType:'',
            itemForm: {
                    camion_id:'',
                    garage_id:''
            },

            fildegarg: [
                {key: 'nom', label: 'Garage', sortable: true},
                { key: 'actions', label: '' }
            ],
            isSubmitted: false,
            isLoading: false,

		}
    },
    validations: {
            itemForm: {
                camion_id: {
                    required
                },
                garage_id: {
                    required
                }
            }

    },
methods:{
        handleSubmitCatg() {

            if(this.currentType)
            {
                this.itemslisttoreType(this.currentType)

            }
            else
            {
                this.flashMessage.show({
                status: 'info',
                title: 'Entrez un nom de garage !!',
                })

            }
        },
        itemslisttoreType(nm){
                        this.isLoading=true;

            this.$http.post('/garages',{
            nom:nm

                })
                .then(response => {

                    this.isLoading=false;
                    this.garages.unshift(response.data.data);

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },

        del_garag_click(itm) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ?? Attention si cet élément est déjà utilisé ,vous NE pouvez PAS le supprimer")
                 .then(() => {


            this.isLoading=true;

            this.$http.delete('/garages/'+itm.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.garages.findIndex(item => item.id === itm.id)


                   this.garages.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });
                                })

                  //erreur de dialog              
                .catch(error=> {
                console.log(error)

                });




      },
        add_garag_click()
        {

        this.currentType=''
        this.$root.$emit('bv::show::modal', 'catmodal')

        },
        fetchCentres: function () {
                    this.isLoading=true;
                    this.$http.get('/camions')
                    .then((result) => {
                                this.camions = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


                                        this.isLoading=true;
                    this.$http.get('/garages')
                    .then((result) => {
                                this.garages = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        handleSubmit(){

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {

                            let cam = this.camions.findIndex(item => item.id === this.itemForm.camion_id)
                            let gar = this.garages.findIndex(item => item.id === this.itemForm.garage_id)

                                            this.$router.push({ 
                                            name: 'listMaintCamion', 
                                            params: { 
                                            idCamion: this.itemForm.camion_id,
                                            idGarage: this.itemForm.garage_id,
                                            nCamion:this.camions[cam].matricul,
                                            nGarage:this.garages[gar].nom
                                            } 
                                                });                                         

                }


        },

       


        



},
components: {
              Loading      
}


}
</script>

<style>

</style>