<template>
<div>
  
               

      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center ">  

            <div class="col">
            <h4 class=" font-weight-lighter text-info ml-3"> <span class="fa fa-dumpster"></span> Casse / Perte </h4> 

            <div class="mt-1 ml-3 d-inline text-secondary font-weight-light">Dépôt : <span class="d-inline text-info"> {{Nsouc}} </span></div>

            </div>



            <div class="col">
              <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Rechercher"></b-form-input>

            </div>                


            
            <div class="col">
                <b-button pill size="sm"  class="m-3 float-right" variant="outline-success" @click="add_click($event.target)">
                            <i class="fa fa-plus-square"></i>
                    <span> Ajouter</span> 
                </b-button>
            </div>

            </div>



        <b-table 
        id="main-table"

        sort-icon-left

        head-variant="light"
        :filter="filter"
        :fields="fields" 
        :items="itemlistStock"
        :per-page="perPage"
        :current-page="currentPage"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 mb-1  float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
                </template>
        
        </b-table>


                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>

      </div>


    <!-- le modal -->
    <b-modal id="banqModal" title="La Casse" size="lg" hide-footer>
           <form @submit.prevent="handleSubmit">


            <div class="row">
                        <div class="form-group col">
                            <label for="centre">Type :</label>
                            <select class="form-control " id="centre" v-model="boiteForm.boite_id"
                            :class="{ 'is-invalid': isSubmitted && $v.boiteForm.boite_id.$error }">
                            <option v-for="item in boites" :key="item.id" v-bind:value="item.boite_id">   
                                {{ item.boite }} - {{item.qte}}
                            </option>

                            </select>
                        </div>
            </div>

            <div class="row">

            <div class="form-group col">
                <label for="date_exp" class="mr-sm-2">Date :</label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-date_exp"
                    placeholder="date"
                    v-model="boiteForm.date"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>   

            <div class="form-group col">
                <label for="qte">Quantité :</label>
                <input type="text" v-model="boiteForm.qte" id="qte" name="qte" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.boiteForm.qte.$error }" />
                <div v-if="isSubmitted && !$v.boiteForm.qte.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.boiteForm.qte.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>
            </div>




            <div class="form-group">
            <label for="Description">Description</label>
            <textarea name="description" id="description"  rows="3" class="form-control" v-model="boiteForm.description"></textarea>

            </div>

            <div class="form-group">
                <button type="submit" class="btn mt-3 btn-primary btn-block">Ajouter</button>
            </div>

        </form>
    </b-modal>


    <!-- les trucs public -->

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";

export default {
 props: ['idDepS','Nsouc'],
    mounted(){
          this.fetchFormdepots()
    },

    computed: {
    user_name() {
      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
      rows_pagin() {
        return this.itemlistStock.length
      }
    },
	data () {
		return {

            filter: null,
            perPage: 10,
            currentPage: 1,
            depots:[],
            boites:[],
            itemlistStock: [],
            boiteForm: {
                    id:'',
                    qte:'',
                    boite_id:'',
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    description:''
                    },
            fields: [
                {key: 'boite', label: 'Caisse', sortable: true},
                {key: 'depot', label: 'Dépôt', sortable: true},
                {key: 'qte', label: 'Quantité', sortable: true},
                {key: 'date', label: 'Date', sortable: true},
                {key: 'description', label: 'Description', sortable: true},
                {key: 'user', label: 'Utilisateur', sortable: true},
                { key: 'actions', label: '' }
            ],
            //public trucs

            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            boiteForm: {
                qte: {
                    required,
                    decimal
                },
                boite_id: {
                    required
                },
                date: {
                    required
                }
            }
    },
    methods :{


        stockstore(){
           this.isLoading=true;


             this.$http.post('/caissCasses',{
            qte:this.boiteForm.qte,
            depot_id:this.idDepS,
            boite_id:this.boiteForm.boite_id,
            description:this.boiteForm.description,
            date:this.boiteForm.date,
            user:this.user_name
                })
                .then(response => {

                    this.isLoading=false;
                    this.itemlistStock.unshift(response.data.data);
                    this.$bvModal.hide('banqModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        depotDell(){

            this.isLoading=true;

            this.$http.delete('/caissCasses/'+this.boiteForm.id)
                .then(response => {
                console.log(response.data)
                    this.isLoading=false;



                    
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.itemlistStock.findIndex(item => item.id === this.boiteForm.id)
                    this.itemlistStock.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        add_click(button) {
        this.boiteForm.id='',
        this.boiteForm.qte=0,
        this.boiteForm.boite_id=''
        this.boiteForm.description=''
        
        this.boiteForm.date=new Date().toJSON().slice(0,10).replace(/-/g,'-')


        this.$root.$emit('bv::show::modal', 'banqModal', button)
        },

        del_click(item) {

                    this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                    .then(() => {
                    this.boiteForm.id=item.id,




                    this.depotDell()
                                    })
                                    .catch(error=> {
                                    console.log(error)

                                        });

        },

       
        fetchFormdepots: function () {
                    //la list:
                                            this.isLoading=true;
                                            this.$http.get('/casseBoiteDep/'+this.idDepS)
                                            .then((result) => {
                                                        this.itemlistStock = result.data.data
                                                        this.isLoading=false;

                                                    
                                            })
                                            .catch(error=> {
                                                        console.log(error)
                                                        this.isLoading=false;
                                                        this.flashMessage.show({
                                                        status: 'error',
                                                        title: 'Erreur de Chargement',
                                                        message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                                        })
                                            });

                    //chager les boites:
                                            this.isLoading=true;
                                            this.$http.get('/stockBoiteDep/'+this.idDepS)
                                            .then((result) => {
                                                        this.boites = result.data.data
                                                        this.isLoading=false;
                                            })
                                            .catch(error=> {
                                                        console.log(error)
                                                        this.isLoading=false;
                                                        this.flashMessage.show({
                                                        status: 'error',
                                                        title: 'Erreur de Chargement',
                                                        message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                                        })
                                            });

                    //
        },
       
       handleSubmit() {
                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                                this.stockstore()
 

                }

            }

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>