<template>
<div>
  
                        
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    


            <h4 class="col ml-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Primes Clients ( Validés )    </h4> 

                    <div class="col">
                                    <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                                                <i class="fa fa-home fa-2x"></i>
                                    </b-button>

                                    <b-button pill size="sm"  class="m-2 float-right" variant="info" @click="fetchList">
                                        <i class="fa fa-sync-alt"></i>
                                        <span> Actualiser</span> 
                                    </b-button>
                    </div>
            </div>
       



        <b-table 


        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"


        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm" class="mr-2 float-right" variant="outline-info" @click="fetchListArts(row.item)">
                            <i class="fa fa-bars"></i>
                    
                    </b-button>
            </template>
        
        </b-table>

        
                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>
      </div>

    <!-- le modal -->
    <b-modal id="detailModal" :title=curentBN size="lg" hide-footer>


       <b-table 
        small 
        borderless
        striped 
        sticky-header
        :fields="fieldsArticle" 
        :items="ArticlesList"
        >

        
        </b-table>
    </b-modal>


    <!-- les trucs public -->

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";

export default {
    computed: {
        
      rows_pagin() {
        return this.itemslist.length
      },
    user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    }
    },
    mounted(){
        this.fetchList()
    },
	data () {
		return {
            perPage: 10,
            currentPage: 1,
            fieldsArticle: [
                {key: 'client', label: 'Client'},
                {key: 'ref', label: 'Réf'},
                {key: 'qte', label: 'Quantité'},
                {key: 'nom_lot', label: 'Lot'},
                {key: 'description', label: 'Description'}
            ],
            ArticlesList:[],

            curentBN:'',

            itemslist: [],

            fields: [
				{key: 'nom', label: 'Nom', sortable: true},
                {key: 'date', label: 'Date', sortable: true},
                {key: 'zone', label: 'Zone', sortable: true},
                {key: 'depot', label: 'Depot', sortable: true},
                {key: 'valider', label: 'Valider', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }, sortable: true},
                {key: 'description', label: 'Description', sortable: true},
                 {key: 'user', label: 'Utilisateur', sortable: true},
                { key: 'actions', label: '' }
            ],
            //public trucs

            isLoading: false
		}
	},
    validations: {
            itemForm: {
                nom: {
                    required
                }
            }
    },
    methods :{
        NewBon()
        {
                    this.$router.push({ 
                        name: 'menuPrimeClient'
                    });
        },
        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/primClientType/1/'+this.user_dep)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        fetchListArts(row_bn)
        {
                    this.isLoading=true;
                    this.$http.get('/detailPrimClient/'+row_bn.id)
                    .then((result) => {
                                this.ArticlesList = result.data.data
                                this.isLoading=false;


                                this.curentBN=row_bn.nom + " ( "+row_bn.date+" )"
                                this.$root.$emit('bv::show::modal', 'detailModal')

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>